import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { createPodcast, editPodcast } from '../../../../../redux/slices/relaxSlice';
import { initialPodcastCover } from '../../../../../constants/relaxRoom';
import ModalContainer from '../../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../../../../components/ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../../../../../components/shared/Buttons/MainButton/MainButton';
import CustomInput from '../../../../../components/shared/CustomInput/CustomInput';
import TextArea from '../../../../../components/shared/TextArea/TextArea';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { Loader } from '../../../../../components/Loader';
import CloseIcon from '@mui/icons-material/Close';
import './CreatePodcast.scss';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../../../../constants';

interface CreatePodcastProps {
  podcast?: Podcast;
  setCreatePodcast: Dispatch<SetStateAction<boolean>>;
  setPodcastReader?: Dispatch<SetStateAction<boolean>>;
}

interface CreatePodcastData {
  title: string;
  link: string;
  review: string;
}

function CreatePodcast({ podcast, setCreatePodcast, setPodcastReader }: CreatePodcastProps) {
  const {
    setValue,
    register,
    handleSubmit,
  } = useForm<CreatePodcastData>({
    defaultValues: {
      title: podcast ? podcast.title : '',
      link: podcast ? podcast.link : '',
      review: podcast ? podcast.description : '',
    }
  });
  const [podcastCover, setPodcastCover] = useState<AttachmentPodcast>(initialPodcastCover);
  const [podcastLoader, setPodcastLoader] = useState<boolean>(false);
  const [onSubmitLoader, setOnSubmitLoader] = useState<boolean>(false);
  const { podcastStatus } = useSelector((state: RootState) => state.relax);
  const lan = localStorage.getItem('lang') || 'en';

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (podcast && podcast.attachments[0]) {
      setPodcastCover({
        type: podcast.attachments[0].type,
        url: podcast.attachments[0].url,
      });
    }
  }, [podcast]);

  useEffect(() => {
    if (podcastStatus === 'loading' && setPodcastReader) {
      setPodcastReader(false);
    }
    if (podcastStatus === 'success') {
      setCreatePodcast(false);
      setOnSubmitLoader(false);
    }
    if (podcastStatus === 'rejected') {
      setOnSubmitLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastStatus]);

  const onAddPodcastCover = async (image: FileList | null) => {
    if (image === null) return;

    const imageSize = image[0].size / (1024 ** 2);
    if (imageSize > 5) {
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }
    setPodcastLoader(true);
    const formData = new FormData();
    formData.append('file', image[0]);
    formData.append('upload_preset', Cloudinary_PRESET);

    await axios
      .post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, formData)
      .then((response: AxiosResponse<any>) => {
        setPodcastCover({
          type: 'PHOTO',
          url: response.data.secure_url,
        });
        setPodcastLoader(false);
      })
      .catch(() => {
        toast.error(formatMessage({ id: 'errorCreateOrEditPost' }));
        setPodcastCover(initialPodcastCover);
        setPodcastLoader(false);
      });
  };

  const onSubmit = (data: CreatePodcastData) => {
    const { title, link, review: description } = data;
    podcast ?
      dispatch(editPodcast({
        podcastId: podcast.id,
        title,
        link,
        description,
        attachment: [podcastCover],
      })) :
      dispatch(createPodcast({
        title,
        link,
        description,
        attachment: [podcastCover],
      }));
    setOnSubmitLoader(true);
  };

  return (
    <ErrorBoundary>
      {onSubmitLoader ?
        <Loader /> :
        <ModalWrapper>
          <ModalContainer>
            <ModalHeader
              textHeader={podcast ?
                formatMessage({ id: 'editPodcast' }) :
                formatMessage({ id: 'addPodcast' })}
              onClose={() => setCreatePodcast(false)}
            />
            <form
              className="CreatePodcast"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CustomInput
                label={formatMessage({ id: 'podcastTitle' })}
                width="100%"
                onChangeFunc={(value: string) => setValue('title', value)}
                defaultValue={podcast ? podcast.title : undefined}
                inputProps={{
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <CustomInput
                label={formatMessage({ id: 'podcastLink' })}
                width="100%"
                onChangeFunc={(value: string) => setValue('link', value)}
                defaultValue={podcast ? podcast.link : undefined}
                inputProps={{
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <TextArea
                type="podcastCover"
                label={formatMessage({ id: 'podcastReview' })}
                uploadImage={onAddPodcastCover}
                register={register}
                name="review"
                defaultValue={podcast ? podcast.description : undefined}
              />

              {podcastCover.url ?
                <div className="CreatePodcast__cover">
                  <CloseIcon
                    className="CreatePodcast__cover-remove"
                    onClick={() => setPodcastCover(initialPodcastCover)}
                  />
                  <img
                    className="CreatePodcast__cover-img"
                    src={podcastCover.url}
                    alt="Podcast cover"
                  />
                </div> :
                <div className="CreatePodcast__cover--empty">
                  {podcastLoader ? <Loader withoutPosition /> : null}
                </div>}

              <ModalFooter>
                <MainButton
                  text={podcast ?
                    formatMessage({ id: 'saveChanges' }) :
                    formatMessage({ id: 'addPodcast' })}
                  type="submit"
                />
              </ModalFooter>
            </form>
          </ModalContainer>
        </ModalWrapper>
      }
    </ErrorBoundary>
  );
}

export default CreatePodcast;
