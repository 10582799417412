import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  createBookAPI,
  deleteBookAPI,
  editBookAPI,
  getBooksAPI,
  getMusicGenresAPI,
  getNextOrPrevEpisodesAPI,
  getPlaylistsAPI,
  getShowEpisodesAPI,
  getShowsAPI,
  getSpotifyAPI,
  getTopPlaylistsByCountryAPI,
  createPodcastAPI,
  deletePodcastAPI,
  editPodcastAPI,
  getPodcastsAPI,
} from '../../api/relaxRoom';
import { RootState } from '../store';
import { intl } from '../../localization';

export interface RelaxState {
  allBooks: Book[];
  allPodcasts: Podcast[];
  inRelaxRoom: boolean;
  spotifyToken: string;
  topPlaylistsByCountry: PlaylistData[];
  musicGenres: MusicGenreData[];
  playlistsByGenre: PlaylistData[];
  shows: PlaylistData[];
  showEpisodes: EpisodeData;
  bookStatus: string;
  podcastStatus: string;
  pageWithEpisodesChange: boolean;
  status: string;
  error: null;
}

const initialState: RelaxState = {
  allBooks: [],
  allPodcasts: [],
  inRelaxRoom: false,
  spotifyToken: '',
  topPlaylistsByCountry: [],
  musicGenres: [],
  playlistsByGenre: [],
  shows: [],
  showEpisodes: {},
  pageWithEpisodesChange: false,
  bookStatus: '',
  podcastStatus: '',
  status: '',
  error: null,
};

const setLoading = (state: RelaxState) => {
  state.status = 'loading';
  state.error = null;
};

const setSuccess = (state: RelaxState) => {
  state.status = 'success';
  state.error = null;
};

const setError = (state: RelaxState, action: PayloadAction<any>) => {
  state.status = 'reject';
  state.error = action.payload;
}

export const getAllBooks = createAsyncThunk(
  'relax/getAllBooks',
  async function (_: void, { rejectWithValue }) {
    try {
      const response = await getBooksAPI();

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createBook = createAsyncThunk(
  'relax/createBook',
  async function (data: BookParams, { rejectWithValue }) {
    try {
      const response = await createBookAPI(data);

      if (response.status !== 201) {
        throw new Error();
      }

    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editBook = createAsyncThunk(
  'relax/editBook',
  async function (data: BookParams, { rejectWithValue }) {
    try {
      const response = await editBookAPI(data);

      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteBook = createAsyncThunk(
  'relax/deleteBook',
  async function (bookId: number, { rejectWithValue }) {
    try {
      const response = await deleteBookAPI(bookId);

      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getAllPodcasts = createAsyncThunk(
  'relax/getAllPodcasts',
  async function (_: void, { rejectWithValue }) {
    try {
      const response = await getPodcastsAPI();

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createPodcast = createAsyncThunk(
  'relax/createPodcast',
  async function (data: PodcastParams, { rejectWithValue }) {
    try {
      const response = await createPodcastAPI(data);

      if (response.status !== 201) {
        throw new Error();
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deletePodcast = createAsyncThunk(
  'relax/deletePodcast',
  async function (podcastId: number, { rejectWithValue }) {
    try {
      const response = await deletePodcastAPI(podcastId);

      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);



export const editPodcast = createAsyncThunk(
  'relax/editPodcast',
  async function (data: PodcastParams, { rejectWithValue }) {
    try {
      const response = await editPodcastAPI(data);

      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getSpotifyToken = createAsyncThunk(
  'relax/getSpotifyToken',
  async function (_: void, { rejectWithValue }) {
    try {
      const response = await getSpotifyAPI();

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.access_token;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getTopPlaylistsByCountry = createAsyncThunk(
  'relax/getTopPlaylistsByCountry',
  async function (_: void, { rejectWithValue, getState }) {
    const spotifyToken = (getState() as RootState).relax.spotifyToken;

    try {
      const response = await getTopPlaylistsByCountryAPI(spotifyToken);

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.playlists.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getMusicGenres = createAsyncThunk(
  'relax/getMusicGenres',
  async function (_: void, { rejectWithValue, getState }) {
    const spotifyToken = (getState() as RootState).relax.spotifyToken;

    try {
      const response = await getMusicGenresAPI(spotifyToken);

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.categories.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getPlaylistsByGenre = createAsyncThunk(
  'relax/getPlaylistsByGenre',
  async function (genreId: string, { rejectWithValue, getState }) {
    const spotifyToken = (getState() as RootState).relax.spotifyToken;

    try {
      const response = await getPlaylistsAPI(spotifyToken, genreId);

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.playlists.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getShows = createAsyncThunk(
  'relax/getShows',
  async function (searchValue: string, { rejectWithValue, getState }) {
    const spotifyToken = (getState() as RootState).relax.spotifyToken;

    try {
      const response = await getShowsAPI(spotifyToken, searchValue);

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data.shows.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getShowEpisodes = createAsyncThunk(
  'relax/getShowEpisodes',
  async function (showId: string, { rejectWithValue, getState }) {
    const spotifyToken = (getState() as RootState).relax.spotifyToken;

    try {
      const response = await getShowEpisodesAPI(spotifyToken, showId);

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getNextOrPrevEpisodes = createAsyncThunk(
  'relax/getNextOrPrevEpisodes',
  async function (link: string, { rejectWithValue, getState }) {
    const spotifyToken = (getState() as RootState).relax.spotifyToken;

    try {
      const response = await getNextOrPrevEpisodesAPI(spotifyToken, link);

      if (response.status !== 200) {
        throw new Error();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const relaxSlice = createSlice({
  name: 'relax',
  initialState,
  reducers: {
    setOnRelaxRoom(state: RelaxState, action: PayloadAction<boolean>) {
      state.inRelaxRoom = action.payload;
    },
    resetShowsData(state: RelaxState) {
      state.shows = [];
      state.showEpisodes = {};
    },
    resetBookStatus(state: RelaxState) {
      state.bookStatus = '';
    },
    resetPodcastStatus(state: RelaxState) {
      state.podcastStatus = '';
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<RelaxState>) => {
    builder
      .addCase(getAllBooks.pending, setLoading)
      .addCase(createBook.pending, (state: RelaxState) => {
        state.bookStatus = 'loading';
      })
      .addCase(editBook.pending, (state: RelaxState) => {
        state.bookStatus = 'loading';
      })
      .addCase(deleteBook.pending, (state: RelaxState) => {
        state.bookStatus = 'loading';
      })
      .addCase(getAllPodcasts.pending, setLoading)
      .addCase(createPodcast.pending, (state: RelaxState) => {
        state.podcastStatus = 'loading';
      })
      .addCase(editPodcast.pending, (state: RelaxState) => {
        state.podcastStatus = 'loading';
      })
      .addCase(deletePodcast.pending, (state: RelaxState) => {
        state.podcastStatus = 'loading';
      })
      .addCase(getSpotifyToken.pending, setLoading)
      .addCase(getTopPlaylistsByCountry.pending, setLoading)
      .addCase(getMusicGenres.pending, setLoading)
      .addCase(getPlaylistsByGenre.pending, setLoading)
      .addCase(getShows.pending, setLoading)
      .addCase(getNextOrPrevEpisodes.pending,
        (state: RelaxState) => {
          state.pageWithEpisodesChange = true;
        })

      .addCase(getAllBooks.fulfilled,
        (state: RelaxState, action: PayloadAction<Book[]>) => {
          state.allBooks = action.payload;
          setSuccess(state);
        })
      .addCase(createBook.fulfilled, (state: RelaxState) => {
        toast.success(intl.intl().formatMessage({ id: 'bookAdded' }));
        state.bookStatus = 'success';
      })
      .addCase(editBook.fulfilled, (state: RelaxState) => {
        toast.success(intl.intl().formatMessage({ id: 'bookEdited' }));
        state.bookStatus = 'success';
      })
      .addCase(deleteBook.fulfilled, (state: RelaxState) => {
        toast.success(intl.intl().formatMessage({ id: 'bookDeleted' }));
        state.bookStatus = 'success';
      })
      .addCase(getAllPodcasts.fulfilled,
        (state: RelaxState, action: PayloadAction<Podcast[]>) => {
          state.allPodcasts = action.payload;
          setSuccess(state);
        })
      .addCase(createPodcast.fulfilled, (state: RelaxState) => {
        toast.success(intl.intl().formatMessage({ id: 'podcastAdded' }));
        state.podcastStatus = 'success';
      })
      .addCase(editPodcast.fulfilled, (state: RelaxState) => {
        toast.success(intl.intl().formatMessage({ id: 'podcastEdited' }));
        state.podcastStatus = 'success';
      })
      .addCase(deletePodcast.fulfilled, (state: RelaxState) => {
        toast.success(intl.intl().formatMessage({ id: 'podcastDeleted' }));
        state.podcastStatus = 'success';
      })
      .addCase(getSpotifyToken.fulfilled,
        (state: RelaxState, action: PayloadAction<string>) => {
          state.spotifyToken = action.payload;
          setSuccess(state);
        })
      .addCase(getTopPlaylistsByCountry.fulfilled,
        (state: RelaxState, action: PayloadAction<PlaylistData[]>) => {
          state.topPlaylistsByCountry = action.payload;
          setSuccess(state);
        })
      .addCase(getMusicGenres.fulfilled,
        (state: RelaxState, action: PayloadAction<MusicGenreData[]>) => {
          state.musicGenres = action.payload;
          setSuccess(state);
        })
      .addCase(getPlaylistsByGenre.fulfilled,
        (state: RelaxState, action: PayloadAction<PlaylistData[]>) => {
          state.playlistsByGenre = action.payload;
          setSuccess(state);
        })
      .addCase(getShows.fulfilled,
        (state: RelaxState, action: PayloadAction<PlaylistData[]>) => {
          state.shows = action.payload;
          setSuccess(state);
        })
      .addCase(getShowEpisodes.fulfilled,
        (state: RelaxState, action: PayloadAction<any>) => {
          state.showEpisodes = action.payload;
          setSuccess(state);
        })
      .addCase(getNextOrPrevEpisodes.fulfilled,
        (state: RelaxState, action: PayloadAction<any>) => {
          state.showEpisodes = action.payload;
          state.pageWithEpisodesChange = false;
          setSuccess(state);
        })

      .addCase(getAllBooks.rejected, setError)
      .addCase(createBook.rejected, (state: RelaxState) => {
        state.bookStatus = 'rejected';
      })
      .addCase(editBook.rejected, (state: RelaxState) => {
        state.bookStatus = 'rejected';
      })
      .addCase(deleteBook.rejected, (state: RelaxState) => {
        state.bookStatus = 'rejected';
      })
      .addCase(getAllPodcasts.rejected, setError)
      .addCase(createPodcast.rejected, (state: RelaxState) => {
        state.podcastStatus = 'rejected';
      })
      .addCase(editPodcast.rejected, (state: RelaxState) => {
        state.podcastStatus = 'rejected';
      })
      .addCase(deletePodcast.rejected, (state: RelaxState) => {
        state.podcastStatus = 'rejected';
      })
      .addCase(getSpotifyToken.rejected, setError)
      .addCase(getTopPlaylistsByCountry.rejected, setError)
      .addCase(getMusicGenres.rejected, setError)
      .addCase(getShows.rejected, setError)
      .addCase(getPlaylistsByGenre.rejected, setError)
      .addCase(getShowEpisodes.rejected, setError)
      .addCase(getNextOrPrevEpisodes.rejected,
        (state: RelaxState, action: PayloadAction<any>) => {
          state.pageWithEpisodesChange = false;
          setError(state, action);
        });
  },
});

export const {
  setOnRelaxRoom,
  resetShowsData,
  resetBookStatus,
  resetPodcastStatus,
} = relaxSlice.actions;

export default relaxSlice.reducer;
