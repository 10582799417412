import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { sharePost } from '../../../redux/slices/postsSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import postDateConvert from '../../helpers/postDateConvert';
import ModalContainer from '../../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../ModalConstructor/ModalWrapper/ModalWrapper';
import { PetitionContact } from '../../PetitionContact/PetitionContact';
import { PostSurveys } from '../../PostSurveys/PostSurveys';
import { PostText } from '../../PostText/PostText';
import MainButton from '../Buttons/MainButton/MainButton';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton';
import TextArea from '../TextArea/TextArea';
import poll from '../../../images/poll.svg';
import advocacy from '../../../images/advocacy-events.svg';
import petition from '../../../images/petition.svg';
import callToAction from '../../../images/call-to-action.svg';
import './SharePostModal.scss';
import { processTextInLink } from '../../helpers/linksProcessing';
import useWindowDimensions from '../../../hooks/widthHook';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

interface Props {
  post: Post;
  setShowSharePostModal: (state: boolean) => void;
}

export const SharePostModal = ({ post, setShowSharePostModal }: Props) => {
  const { formatMessage } = useIntl();
  const [sharePostDescr, setSharePostDescr] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const { someUserId } = useParams();
  const profile = useSelector((state: RootState) => state.profile);
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <>
      <ModalWrapper
        width={useWindowDimensions().width >= 1100 ? '609px' : '100%'}
        isMobile={useWindowDimensions().width <= 1100 && true}
      >
        <ModalContainer isMobile={useWindowDimensions().width <= 1100 && true}>
          <ModalHeader
            textHeader={formatMessage({ id: 'sharePostToFeed' })}
            onClose={() => setShowSharePostModal(false)}
            arrow={useWindowDimensions().width <= 1100 && true}
          />
          <div className="share-to-feed__descr">
            <TextArea
              placeholder={formatMessage({ id: 'writeDescription' })}
              label={formatMessage({ id: 'description' })}
              setValue={setSharePostDescr}
            />
          </div>

          <div className="share-to-feed__post">
            {/* sharing body ================================================================ */}
            {/* sharing body ================================================================ */}
            <div className="post share-to-feed--background">
              <>
                {post?.types?.typeName !== 'DEFAULT' &&
                post?.types?.typeName !== 'SURVEY/POLLS' &&
                post?.types?.typeName !== 'CALLS_TO_ACTION' &&
                post?.types?.typeName !== 'EVENTS' &&
                post?.types?.typeName !== 'PETITIONS' ? (
                  <div className="post__type">
                    <h2 className="post__type-title">
                      {post?.types?.typeName}
                    </h2>
                  </div>
                ) : (
                  ''
                )}

                {post?.types?.typeName === 'SURVEY/POLLS' ? (
                  <div className="post__type">
                    <img className="post__type-img" src={poll} alt="" />
                    <h2 className="post__type-title">
                      {formatMessage({ id: 'surveyPoll' }).toUpperCase()}
                    </h2>
                  </div>
                ) : (
                  ''
                )}

                {post?.types?.typeName === 'CALLS_TO_ACTION' ? (
                  <div className="post__type">
                    <img className="post__type-img" src={callToAction} alt="" />
                    <h2 className="post__type-title">
                      {formatMessage({ id: 'callToAction' }).toUpperCase()}
                    </h2>
                  </div>
                ) : (
                  ''
                )}

                {post?.types?.typeName === 'EVENTS' ? (
                  <div className="post__type">
                    <img className="post__type-img" src={advocacy} alt="" />
                    <h2 className="post__type-title">
                      {formatMessage({ id: 'advocacyEvent' }).toUpperCase()}
                    </h2>
                  </div>
                ) : (
                  ''
                )}

                {post?.types?.typeName === 'PETITIONS' ? (
                  <div className="post__type">
                    <img className="post__type-img" src={petition} alt="" />
                    <h2 className="post__type-title">
                      {formatMessage({ id: 'petition' }).toUpperCase()}
                    </h2>
                  </div>
                ) : (
                  ''
                )}
              </>

              <div
                className="post__container share-to-feed__post__container"
                style={{
                  textAlign: 'left'
                }}
              >
                <div
                  className="post__header"
                  style={{
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                  }}
                >
                  <div
                    className="post__header-user-container"
                    style={{
                      flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                      marginRight: lan === 'ar' ? '0' : 'auto',
                      marginLeft: lan === 'ar' ? 'auto' : '0'
                    }}
                  >
                    <div
                      className="post__header-avatar-container"
                      style={{
                        width: `${post.type === 'SHARED' && '32px'}`,
                        height: `${post.type === 'SHARED' && '32px'}`,
                        margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0'
                      }}
                    >
                      <img
                        className="post__header-avatar"
                        src={post.owner.profileAvatar}
                        alt=""
                      />
                    </div>

                    <div
                      className="post__header-date-container"
                      style={{
                        alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
                      }}
                    >
                      <Link to="/my-profile">
                        <h2 className="post__header-name">
                          {`${post.owner.organizationName}` ||
                            `${post.owner.firstName} ${post.owner.lastName}`}
                        </h2>
                      </Link>

                      <span className="post__header-date">
                        {postDateConvert(post.created) + ' ' + (post.pinned ? '(pinned)' : '')}
                      </span>
                    </div>
                  </div>
                </div>
                {/* ======================================== /post header ======================================== */}

                <div className="post__body">
                  {post.types?.typeName !== 'DEFAULT' && (
                    <h2
                      className="post__advocacy-title"
                      style={{
                        textAlign: (lan === 'ar') ? 'right' : 'left'
                      }}
                    >
                      {processTextInLink(post.title)}
                    </h2>
                  )}

                  <div
                    className="post__body-text-container share-to-feed__post__body-text-container"
                    style={{
                      textAlign: (lan === 'ar') ? 'right' : 'left'
                    }}
                  >
                    <PostText post={post} sharedModal />

                    {post.types?.typeName === 'SURVEY/POLLS' && (
                      <PostSurveys post={post} sharedModal />
                    )}

                    {post.types?.typeName === 'PETITIONS' && post.email && (
                      <PetitionContact email={post.email} isSharing={true} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* sharing body ================================================================ */}
            {/* sharing body ================================================================ */}
          </div>
          <ModalFooter>
            {/* width={(useWindowDimensions().width > 1100) ? '609px' : '100%'} */}
            {useWindowDimensions().width >= 1100 && (
              <SecondaryButton
                text={formatMessage({ id: 'cancel' })}
                onClick={() => {
                  setShowSharePostModal(false);
                }}
                bigPadding
              />
            )}

            <MainButton
              text={formatMessage({ id: 'sharePost' })}
              onClick={async () => {
                setShowSharePostModal(false);
                // await postShareAPI(post.id, sharePostDescr);
                dispatch(
                  sharePost({
                    postId: post.id,
                    sharePostDescr,
                    someUserId,
                    profileId: profile.id,
                  }),
                );
              }}
              bigPadding
            />
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};
