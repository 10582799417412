import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import axios from 'axios';

import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';

import MainButton from '../shared/Buttons/MainButton/MainButton';
import CheckBoxLabel from '../shared/CheckBoxLabel/CheckBoxLabel';
import CustomInput from '../shared/CustomInput/CustomInput';
import CustomChip from '../shared/CustomChip/CustomChip';
import TextArea from '../shared/TextArea/TextArea';

import {
  createPost,
  editPost,
  setErrorNull,
  setTypeOfNewPost,
} from '../../redux/slices/postsSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { showLoader } from '../../redux/slices/appSlice';
import useWindowDimensions from '../../hooks/widthHook';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../constants';
import { getTitleTypeOfPost } from '../helpers/getTitleTypeOfPost';
import { getVideoPoster } from '../helpers/getVideoPoster';
import DataPicker from '../DataPicker/DataPicker';
import DropdawnCreateAccounts from '../DropdawnCreateAccounts/DropdawnCreateAccounts';

import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import VideoLibraryTwoToneIcon from '@mui/icons-material/VideoLibraryTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import interestsTag from '../../images/interest-areas.svg';
import './PostCreation.scss';


type Props = {
  type: 'edit' | 'create';
  close: (typeOfAction: string) => void;
};

const PostCreation: React.FC<Props> = ({ type, close }) => {
  const { formatMessage } = useIntl();
  let location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams() || '';
  const typeId = searchParams.get('typeId') || '';
  const interestId = searchParams.get('interestId') || '';
  const groupId = searchParams.get('groupId') || '';
  const lan = localStorage.getItem('lang') || 'en';
  const { width } = useWindowDimensions();

  const _URL = window.URL || window.webkitURL;
  const { status, typeOfNewPost, editedPost } = useSelector(
    (state: RootState) => state.posts,
  );

  const { postTypes } = useSelector((state: RootState) => state.app);
  const { language: lang, isAdmin, anotherUserId } = useSelector((state: RootState) => state.profile);
  const chips = useSelector((state: RootState) => state.app.allInterests
    .filter(item => !item.isArchived));

  const [email, setEmail] = useState<string>(editedPost ? editedPost?.email : ' ');
  const [isOpenInterestsList, setIsOpenInterestsList] = useState<boolean>(false);
  const [selectedChips, setSelectedChips] = useState<SelectedChip[] | Chip[] | []>(
    (type === 'edit' && editedPost && editedPost.interests) ?
      (editedPost.interests.map((interest: Chip) => {
        return {
          title: interest.title,
          url: interest.url,
          id: interest.id,
          isArchived: interest.isArchived,
        }
      })) : []);
  const [btnSelectChecked, setBtnSelectChecked] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<MediaItem[]>(
    editedPost ? editedPost.attachments : []
  );
  const [typeOfMedia, setTypeOfMedia] = useState<string>(selectedMedia.length !== 0 ?
    selectedMedia[0].type : '');
  const [videoSrc, setVideoSrc] = useState<string>('');
  const [permission, setPermission] = useState<boolean>(editedPost ?
    editedPost?.showEmail : true);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [scheduleTime, setScheduleTime] = useState<number>(0);
  const [pinned, setPinned] = useState<boolean | number>(editedPost?.pinned || 0);
  const [showModal, setShowModal] = useState<boolean | number>(editedPost?.showModal || 0);
  const [open, setOpen] = useState(false);

  const myInputRef = useRef<any>(null);

  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: editedPost ? editedPost?.email : undefined,
      postQuestion: (type === 'edit' && editedPost)
        ? editedPost.answers?.map((answer: Answer) => {
          return { value: answer.title }
        }) : [{ value: '' }, { value: '' }],
      scheduleTime: scheduleTime || 0
    }
  });

  const {
    fields,
    remove,
    append
  } = useFieldArray({
    control,
    name: 'postQuestion',
  });

  const getOptionAnswearTitle = (index: number) => {
    if (lang === 'es') return `${index}°`;
    if (lang === 'pt') return `${index}º`;
    if (lang === 'en') {
      switch (index) {
        case 1:
          return `${index}st`;
        case 2:
          return `${index}nd`;
        case 3:
          return `${index}rd`;
        default:
          return `${index}th`;
      }
    }
    if (lang === 'fr') {
      switch (index) {
        case 1:
          return `${index}er`;
        default:
          return `${index}ème`;
      }
    }
    return `${index}`;
  };

  const uploadImage = async (files: FileList | null) => {
    const ImageFormat = ['.png', '.jpg', '.jpeg'];
    const VideoFormat = ['mp4', 'mov', 'mkv', 'avi', 'wmv', 'flv'];
    const maxImageSize = 5242880;
    const maxVideoSize = 52428800;

    if (files === null) return;

    if (Array.from(files).every(file => file.type.split('/')[0] === 'image')) {
      if ((files.length > 5) || ((files.length + selectedFiles.length) > 5) ||
        (selectedMedia.length > 5) || ((files.length + selectedMedia.length + selectedFiles.length) > 5)) {
        toast.error(formatMessage({ id: 'notMoreFivePhoto' }));
        return;
      }

      if (!Array.from(files).some(
        file => !ImageFormat.includes(file.name.split('.')[1]),
      )) {
        toast.error(formatMessage({ id: 'correctPhotosFormat' }));
        return;
      }

      if (Array.from(files).some(file => file.size > maxImageSize)) {
        toast.error(formatMessage({ id: 'correctPhotosWeight' }));
        return;
      }

      if ((selectedFiles.length !== 0 && typeOfMedia === 'VIDEO') ||
        (selectedMedia.length !== 0 && typeOfMedia === 'VIDEO')) {
        toast.error(formatMessage({ id: 'photoOrVideo' }));
        return;
      } else {
        setSelectedFiles([...selectedFiles, ...Array.from(files)]);
      }
      setTypeOfMedia('PHOTO');

    } else if (
      Array.from(files).every(file => (file.type.split('/')[0] === 'video')
        || (file.type === ''))
    ) {
      const array = files[0].name.split('.');

      if (!VideoFormat.includes(array[array.length - 1].toLowerCase())) {
        toast.error('Please select video with extensions: MP4, MOV, MKV, WMV, AVI and FLV');
        return;
      }

      if (files[0].size > maxVideoSize) {
        toast.error(formatMessage({ id: 'correctVideoWeight' }));
        return;
      } else {
        // setVideoSrc(URL.createObjectURL(files[0])); --needs for display video preview
        setVideoSrc(files[0].name);
      }

      if (files.length > 1) {
        toast.error(formatMessage({ id: 'onlyOneVideo' }));
        return;
      }

      if ((selectedFiles.length !== 0 && typeOfMedia === 'PHOTO') ||
        (selectedMedia.length !== 0 && typeOfMedia === 'PHOTO')) {
        toast.error(formatMessage({ id: 'photoOrVideo' }));
        return;
      }
      setSelectedFiles([...Array.from(files)]);
      setTypeOfMedia('VIDEO');
    } else {
      toast.error(formatMessage({ id: 'photoOrVideo' }));
      return;
    }
  };

  useEffect(() => {
    location.pathname === '/groups' &&
      interestId !== '' &&
      setSelectedChips(chips
        .filter((chip: Chip) => !chip.isArchived)
        .filter((chip: Chip) => +chip.id === +interestId));
  }, [interestId, location.pathname]);

  useEffect(() => {
    if (status === 'success' && type === 'create') {
      toast.success(formatMessage({ id: 'successCreatedPost' }));
      close(type);
      dispatch(setErrorNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  useEffect(() => {
    if (status === 'success' && type === 'edit') {
      close(type);
      dispatch(setErrorNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status]);

  useEffect(() => {
    if ((typeOfMedia === 'VIDEO' && selectedFiles.length === 1 && videoSrc !== '')
      || (selectedFiles.length === 5)
      || ((selectedFiles.length + selectedMedia.length) === 5)
      || (typeOfMedia === 'VIDEO' && selectedMedia.length === 1 && videoSrc !== '')) {
      setIsDisabledBtn(true);
    } else {
      setIsDisabledBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfMedia, selectedFiles, selectedMedia]);

  const deleteMedia = async (file: File | MediaItem, type: string) => {
    if (file.type.split('/')[0] === 'video') {
      setIsDisabledBtn(false);
    }

    type === 'new'
      ? setSelectedFiles(
        selectedFiles.filter(selectedFile => selectedFile !== file),
      )
      : setSelectedMedia(
        selectedMedia.filter(media => media !== file,
          console.log('selectedMedia', selectedMedia[0] !== file)),
      );
  };

  useEffect(() => {
    if (!scheduleTime) {
      myInputRef.current.value = 0;
    } else {
      myInputRef.current.value = scheduleTime;
    }
    
    setTimeout(() => (scheduleTime > 0 || scheduleTime < 0) && close(type), 300);
  }, [close, scheduleTime, type]);

  const onSubmit = async (data: any) => {
    dispatch(showLoader(true));
    const time = myInputRef?.current?.value;
    let files: string[] = [];

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append('file', selectedFiles[i]);
        formData.append('upload_preset', Cloudinary_PRESET);
        await axios
          .post(
            `https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/${typeOfMedia === 'VIDEO' ?
              'video' : 'image'}/upload`,
            formData,
          )
          .then(response => {
            files.push(response.data.secure_url);
          });
      }
    }

    const attachment = selectedMedia
      ? [
        ...files.map(file => {
          return { type: typeOfMedia, url: file };
        }),
        ...selectedMedia.map(file => {
          return { type: file.type, url: file.url };
        }),
      ] : [
        ...files.map(file => {
          return { type: typeOfMedia, url: file };
        }),
      ];

    const newPost = {
      typeId: typeOfNewPost.id,
      delay: +time || 0,
      postId: editedPost ? editedPost.id : 0,
      title: data.postTitle,
      description: data.postDescription.trim(),
      interests: [...selectedChips.map(chip => +chip.id)],
      attachment: attachment,
      showEmail: permission,
      email: data.email,
      pinned: pinned === 1 || pinned === true ? true : false,
      showModal: showModal === 1 || showModal === true ? true : false,
      answers: data.postQuestion
        .filter((question: any) => question.value !== '')
        .map((question: any) => question.value),
    };

    if (type === 'create') {
      if (typeOfNewPost.typeName === 'SURVEY/POLLS') {
        const createObj = {
          title: newPost.title,
          delay: newPost.delay,
          description: newPost.description,
          interests: newPost.interests,
          typeId: typeOfNewPost.id,
          attachment: newPost.attachment,
          group: groupId ? +groupId : null,
          answers: newPost.answers,
          showEmail: !!newPost.showEmail,
          selectAll: btnSelectChecked,
          email,
          pinned: pinned === 1 || pinned === true ? true : false,
          showModal: showModal === 1 || showModal === true ? true : false ,
        } as any;

        if (anotherUserId?.user) {
          createObj.userId = anotherUserId?.user;
        };

        dispatch(createPost(createObj))
      } else {
        const createObj = {
          title: newPost.title,
          delay: newPost.delay,
          description: newPost.description,
          interests: newPost.interests,
          typeId: typeOfNewPost.id ? typeOfNewPost.id : 5,
          attachment: newPost.attachment,
          group: groupId ? +groupId : null,
          showEmail: !!newPost.showEmail,
          selectAll: btnSelectChecked,
          email: newPost.email ? newPost.email : email,
          pinned: pinned === 1 || pinned === true ? true : false,
          showModal: showModal === 1 || showModal === true ? true : false,
        } as any;

        if (anotherUserId?.user) {
          createObj.userId = anotherUserId?.user;
        };

        dispatch(createPost(createObj))
      }
    } else {
      dispatch(editPost({
        postId: newPost.postId,
        title: newPost.title,
        description: newPost.description,
        interests: newPost.interests,
        attachment: newPost.attachment,
        answers: newPost.answers,
        showEmail: !!newPost.showEmail,
        selectAll: btnSelectChecked,
        email: newPost.email ? newPost.email : email,
        pinned: pinned === 1 || pinned === true ? true : false,
        showModal: showModal === 1 || showModal === true ? true : false,
      }))
    }

    reset();
    setSelectedChips([]);
  };

  useEffect(() => {
    (editedPost && editedPost.interests) &&
      setSelectedChips(editedPost.interests.map((interest: Chip) => {
        return {
          title: interest.title,
          url: interest.url,
          id: interest.id,
          isArchived: interest.isArchived,
        }
      }));
  }, [editedPost]);

  return (
    <div className="PostCreationMobile">
      <ModalWrapper
        width={(useWindowDimensions().width > 768) ? '607px' : '100%'}
        isMobile={(useWindowDimensions().width <= 768) && true}
      >
        <ModalContainer isMobile={(useWindowDimensions().width <= 768) && true}>
          <ModalHeader
            textHeader={`${type === 'create' ?
              formatMessage({ id: 'create' }) :
              formatMessage({ id: 'edit' })} ${formatMessage({ id: 'aPost' })}`}
            postCreation={true}
            onClose={() => close(type)}
            arrow={(useWindowDimensions().width <= 768) && true}
          />
          {(useWindowDimensions().width > 768) && <div className="postCreation__divider"></div>}

          <form
            className="postCreation__form"
            onSubmit={handleSubmit(onSubmit)}>
            {(typeOfNewPost.typeName !== 'DEFAULT' && type === 'create') && (
              <div className="postCreation__typeOfPost postCreation__typeOfPost--mobile">
                {postTypes
                  ?.filter((postType: any) => !postType.isArchived)
                  ?.map((postType: any) => {
                    if (postType.id !== 5 && postType.id !== 6) {
                      return (
                        <button
                          type='button'
                          key={postType.id}
                          data-disabled={
                            location.pathname === '/groups' &&
                            typeId !== '' &&
                            +typeId !== postType.id
                          }
                          className={`postCreation__buttonType` +
                            ` postCreation__buttonType--default` +
                            `${typeOfNewPost.id === postType.id ?
                              ' postCreation__buttonType--selected' : ''}`}
                          onClick={() => dispatch(setTypeOfNewPost(postType))}
                        >
                          {postType.id < 6 ?
                            formatMessage({ id: getTitleTypeOfPost(postType.typeName) }) :
                            postType.typeName}
                        </button>
                      );
                    }
                    return null;
                  })}
              </div>
            )}

            {isAdmin && <DropdawnCreateAccounts />}
            
            <input
              type="hidden"
              {...register("scheduleTime")}
              value={scheduleTime}
              ref={myInputRef}
            />

            {typeOfNewPost.typeName !== 'DEFAULT'
              && typeOfNewPost?.typeName !== undefined
              && typeOfNewPost.typeName !== 'SURVEY/POLLS'
              && editedPost?.type !== 'SHARED'
              && (
                <div className="postCreation__inputItem">
                  <CustomInput
                    placeholder={formatMessage({ id: 'writeTitleHere' })}
                    label={`${formatMessage({ id: 'titleForYour' })} ` +
                      `${typeOfNewPost.id < 6 ?
                        formatMessage({ id: getTitleTypeOfPost(typeOfNewPost.typeName) }) :
                        typeOfNewPost.typeName}`}
                    inputProps={{
                      //@ts-ignore
                      ...register('postTitle', {
                        required: formatMessage({ id: 'fieldIsEmpty' }),
                      }),
                      style: {
                        fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                        textAlign: lan === 'ar' ? 'right' : 'left'
                      }
                    }}
                    defaultValue={editedPost?.title}
                  />
                </div>
              )}

            {typeOfNewPost.typeName === 'SURVEY/POLLS' && editedPost?.type !== 'SHARED' && (
              <>
                <div className="postCreation__inputItem">
                  <CustomInput
                    placeholder={formatMessage({ id: 'writeQuestion' })}
                    label={formatMessage({ id: 'question' })}
                    defaultValue={editedPost?.title}
                    inputProps={{
                      //@ts-ignore
                      ...register('postTitle', {
                        required: formatMessage({ id: 'fieldIsEmpty' }),
                      }),
                      style: {
                        fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                        textAlign: lan === 'ar' ? 'right' : 'left'
                      }
                    }}
                  />
                </div>

                {fields.map((field: any, index: number) => (
                  <div key={field.id} className="postCreation__inputItem">
                    <Controller
                      //@ts-ignore
                      name={`postQuestion[${index}]` as const}
                      control={control}
                      // defaultValue={type === 'edit' ? editedPost?.answers[index]?.title : field.value}
                      defaultValue={editedPost?.answers[index]?.title}
                      render={({ field }) => (
                        <CustomInput
                          placeholder={`${formatMessage({ id: 'writeAnswer' })} ${index + 1} ${formatMessage({ id: 'here' })}...`}
                          label={`${getOptionAnswearTitle(index + 1)} ${formatMessage({ id: 'answerOption' })}`}
                          inputProps={{
                            //@ts-ignore
                            ...register(`postQuestion.${index}.value` as const, {
                              required: index < 2 && formatMessage({ id: 'fieldIsEmpty' }),
                            }),
                            style: {
                              fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                              textAlign: lan === 'ar' ? 'right' : 'left'
                            }
                          }}
                        />
                      )}
                    />
                    {index > 1 && <CloseIcon onClick={() => remove(index)} />}
                  </div>
                ))}

                <button
                  className="postCreation__buttonAdd"
                  onClick={() => append({ value: '' })}
                  disabled={getValues('postQuestion').length === 10}
                  type="button"
                  style={{
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                  }}
                >
                  <AddCircleTwoToneIcon />
                  {formatMessage({ id: 'addAnotherAnswerOption' })}
                </button>
              </>
            )}

            <TextArea
              label={formatMessage({ id: 'description' })}
              placeholder={formatMessage({ id: 'writeDescription' })}
              type={editedPost?.type === 'SHARED' ? "" : "postCreation"}
              uploadImage={uploadImage}
              name="postDescription"
              register={register}
              errors={errors}
              defaultValue={editedPost?.description}
              isDisabledBtn={isDisabledBtn}
            />

            <div className="postCreation__imgsList">
              {(editedPost && editedPost.attachments.length && editedPost.attachments[0].type === 'PHOTO') ?
                selectedMedia.map((file: MediaItem) => (
                  <div className="postCreation__mediaItem" key={file.id}>
                    <div className="postCreation__mediaContainer">
                      <img src={file.url} alt="" />
                    </div>
                    <CloseIcon onClick={() => deleteMedia(file, 'default')} />
                  </div>
                )) : (null)}

              {typeOfMedia === 'PHOTO' &&
                selectedFiles.map((file: File, index: number) => (
                  <div className="postCreation__mediaItem" key={index}>
                    <div className="postCreation__mediaContainer">
                      <img src={_URL.createObjectURL(file)} alt="" />
                    </div>
                    <CloseIcon onClick={() => deleteMedia(file, 'new')} />
                  </div>
                ))}

              {(editedPost?.attachments.length && editedPost.attachments[0].type === 'VIDEO') ?
                selectedMedia.map((file: MediaItem) => (
                  <div className="postCreation__mediaItem postCreation__mediaItem--video">
                    <div className="postCreation__mediaContainer postCreation__mediaContainer--video">
                      <video
                        poster={getVideoPoster(file.url)}
                        src={file.url}
                        playsInline
                      />
                    </div>
                    <CloseIcon onClick={() => deleteMedia(file, 'default')} />
                    <PlayCircleFilledRoundedIcon />
                  </div>
                )) : (null)}

              {typeOfMedia === 'VIDEO' && videoSrc !== '' && (
                <div className="postCreation__attachment">
                  <VideoLibraryTwoToneIcon />
                  {videoSrc}
                  <CloseIcon
                    onClick={() => {
                      setVideoSrc('');
                      setIsDisabledBtn(false);
                    }}
                  />
                </div>

                //=============Video With Preview==============
                // <div className="postCreation__mediaItem postCreation__mediaItem--video">
                //   <div className="postCreation__mediaContainer postCreation__mediaContainer--video">
                //     <video
                //       poster={videoSrc}
                //       src={videoSrc} />
                //   </div>
                //   <CloseIcon
                //     onClick={() => {
                //       setVideoSrc('');
                //       setIsDisabledBtn(false);
                //     }}
                //   />
                //   <PlayCircleFilledRoundedIcon />
                // </div>

              )}
            </div>

            {typeOfNewPost.typeName === 'PETITIONS' && editedPost?.type !== 'SHARED' && (
              <>
                <CheckBoxLabel
                  label={formatMessage({ id: 'allowPeopleToEmail' })}
                  setValue={() => setPermission(!permission)}
                  defaultChecked={type === 'edit' ? editedPost?.showEmail : true}
                />
                <div className="postCreation__helpText">
                  {formatMessage({ id: 'understandUsersSeeEmail' })}
                </div>
                <CustomInput
                  placeholder={formatMessage({ id: 'emailPlaceholder' })}
                  inputName="email"
                  errors={errors}
                  handleError={permission ?
                    (email?.length ? false : true) :
                    false}
                  touchedFields={touchedFields}
                  onChangeFunc={(value: any) => {
                    setValue('email', value);
                    setEmail(value);
                  }}
                  disabled={!permission}
                  width="100%"
                  inputProps={{
                    ...register('email', {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: formatMessage({ id: 'incorrectEmail' }),
                      },
                    }),
                    style: {
                      fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                      textAlign: lan === 'ar' ? 'right' : 'left'
                    }
                  }}
                />
              </>)}

            {isAdmin && (
              <>
                <CheckBoxLabel
                  label={formatMessage({ id: 'pinnedPost' })}
                  setValue={() => setPinned(!pinned)}
                  defaultChecked={pinned === 1 || pinned === true ? true : false}
                />
                <br />
              </>
            )}

            {isAdmin && (
              <>
                <CheckBoxLabel
                  label={formatMessage({ id: 'showModal' })}
                  setValue={() => setShowModal(!showModal)}
                  defaultChecked={showModal === 1 || showModal === true ? true : false}
                />
                <br />
              </>
            )}

            {editedPost?.type !== 'SHARED' && (
              <>
                <div className="postCreation__divider"></div>
                {/* <div
                  className="postCreation__interestsInfo"
                  onClick={() => setIsOpenInterestsList(!isOpenInterestsList)}
                  style={{
                    flexDirection: (lan === 'ar') ? 'row-reverse' : 'row'
                  }}
                > */}
                  {/* <div
                    className={`postCreation__interestsBtn${selectedChips
                      .filter((chip: any) => !chip.isArchived)
                      .length !== 0 || isOpenInterestsList
                      ? ' postCreation__interestsBtn--selected' : ''}`}
                  >
                    <img src={interestsTag} alt="interests tag icon" />
                    {formatMessage({ id: 'selectInterests' })} */}
                    {/* <span className="postCreation__interestsBtn-required">
                  ({formatMessage({ id: 'required' })})
                </span> */}
                  {/* </div> */}
                  {/* {!isOpenInterestsList ? (
                    <ArrowDropDownIcon style={{ color: '#6C757D' }} />
                  ) : (
                    <ArrowDropUpIcon style={{ color: '#6C757D' }} />
                  )} */}
                {/* </div> */}
                {/* <div
                  className="postCreation__countSelectedChips"
                  style={{
                    marginBottom: isOpenInterestsList ? '0' : '16px'
                  }}
                >
                  {!isOpenInterestsList && selectedChips.length !== 0 &&
                    `${selectedChips.filter((chip: any) => !chip.isArchived)
                      .length} ${formatMessage({ id: 'tagSelected' })}`}
                </div> */}
              </>
            )}

            {/* {isOpenInterestsList && (
              <div className="postCreation__interestsList">
                <div className="postCreation__btnSelectAll">
                  <CustomChip
                    label={btnSelectChecked ?
                      formatMessage({ id: 'resetAll' }) :
                      formatMessage({ id: 'selectAll' })}
                    src=""
                    id=""
                    isArchived
                    mobile={width < 600}
                    defaultChecked={btnSelectChecked}
                    onClick={() => {
                      setSelectedChips(!btnSelectChecked ? [...chips] : []);
                      setBtnSelectChecked(!btnSelectChecked);
                    }}
                  />
                </div>
              
                <div className="postCreation__chips">
                  {chips
                    ?.filter((item: Chip) => !item.isArchived)
                    .map((item: Chip, index: number) => (
                      <CustomChip
                        key={index}
                        label={item.title}
                        src={item.avatar || item.url}
                        isArchived={item.isArchived}
                        id={item.id.toString()}
                        defaultChecked={
                          selectedChips.some(chip => chip.title === item.title) &&
                          true
                        }
                        setSelectedChips={(+item.id !== +interestId) && setSelectedChips}
                      />
                    ))}
                </div>
              </div>
            )} */}

            <div
              className="postCreation__btn-container"
              style={{
                top: width < 768 ? (interestId ? '30px' : '24px') : '0',
                height: '43px'
              }}
            >
              <ModalFooter isMobile={(useWindowDimensions().width <= 768)}>
                <MainButton
                  text={type === 'create'
                    ? `${formatMessage({ id: 'create' })} ${formatMessage({ id: 'aPost' })}`
                    : `${formatMessage({ id: 'doneEditing' })}`}
                  type="submit"
                  disabled={!isValid || (
                    typeOfNewPost.typeName === 'PETITIONS' ?
                      (permission && email?.length && email?.length !== 1 ?
                        false : (!permission ? false : true)) :
                      false) || open}
                />
                {(type === 'create' && isAdmin) && (
                  <DataPicker
                    setScheduleTime={setScheduleTime}
                    setOpen={setOpen}
                    onSubmit={handleSubmit(onSubmit)}
                    disabled={!isValid || (
                      typeOfNewPost.typeName === 'PETITIONS' ?
                        (permission && email?.length && email?.length !== 1 ?
                          false : (!permission ? false : true)) :
                        false)}
                  />
                )}
              </ModalFooter>
            </div>
          </form>
        </ModalContainer>
      </ModalWrapper>

    </div>
  );
};

export default PostCreation;
