import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  MouseEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../../../redux/store';
import {
  getAllPodcasts,
  deletePodcast,
  resetPodcastStatus,
} from '../../../../redux/slices/relaxSlice';
import ModalContainer from '../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './PodcastsModal.scss';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import PodcastReader from './PodcastReader/PodcastReader';
import CreatePodcast from './CreatePodcast/CreatePodcast';

interface PodcastsModalProps {
  setPodcastsModal: Dispatch<SetStateAction<boolean>>;
  trends: number[];
}

function PodcastsModal({ setPodcastsModal, trends }: PodcastsModalProps) {
  const [createPodcast, setCreatePodcast] = useState<boolean>(false);
  const [readPodcast, setReadPodcast] = useState<boolean>(false);
  const [asAdmin, setAsAdmin] = useState<boolean>(false);
  const [podcast, setPodcast] = useState<Podcast>();
  const { allPodcasts, podcastStatus } = useSelector(
    (state: RootState) => state.relax,
  );
  const { isAdmin } = useSelector((state: RootState) => state.profile);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (podcastStatus === 'success') {
      dispatch(getAllPodcasts());
      dispatch(resetPodcastStatus());
    }
  }, [dispatch, podcastStatus]);

  useEffect(() => {
    if (isAdmin) {
      setAsAdmin(true);
    } else {
      setAsAdmin(false);
    }
  }, [isAdmin]);

  return (
    <>
      {!createPodcast && !readPodcast && (
        <ModalWrapper>
          <ModalContainer
            styles={{
              backgroundColor: '#FBF5FF',
              borderRadius: '12px',
              padding: '0',
            }}
          >
            <ModalHeader
              mainColor
              withPadding
              textHeader={formatMessage({ id: 'podcastReviewsTitle' })}
              onClose={() => setPodcastsModal(false)}
            />
            <div className="PodcastsModal">
              <div className="PodcastsModal__container">
                {allPodcasts.length ? (
                  <>
                    {allPodcasts.map((podcast: Podcast) => (
                      <div key={podcast.id} className="Book">
                        {asAdmin && (
                          <button
                            className="Podcast__deleteBtn"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(deletePodcast(podcast.id));
                            }}
                          >
                            <DeleteOutlineIcon />
                          </button>
                        )}
                        <button
                          className="Podcast__container"
                          type="button"
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            setPodcast(podcast);
                            setReadPodcast(true);
                          }}
                        >
                          <div
                            className="Podcast__coverContainer"
                            style={{ height: '292px', width: '210px' }}
                          >
                            <img
                              className="Podcast__cover"
                              src={
                                podcast.attachments && podcast.attachments.length
                                  ? podcast.attachments[0].url
                                  : ''
                              }
                              alt={podcast.title}
                              // width="210px"
                              // height="292px"
                            />
                          </div>
                          <div className="Podcast__info">
                            <p className="Podcast__title">{podcast.title}</p>
                          </div>
                        </button>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="PodcastsModal__noPodcasts">
                    {formatMessage({ id: 'podcastNoAdded' })}
                  </p>
                )}
              </div>
            </div>
            {asAdmin && (
              <div className="PodcastsModal__buttons">
                <MainButton
                  text={formatMessage({ id: 'addPodcast' })}
                  onClick={() => setCreatePodcast(true)}
                />
              </div>
            )}
          </ModalContainer>
        </ModalWrapper>
      )}
      {createPodcast && <CreatePodcast setCreatePodcast={setCreatePodcast} />}
      {podcast && readPodcast && (
        <PodcastReader
          asAdmin={asAdmin}
          podcast={podcast}
          setPodcastReader={setReadPodcast}
          setPodcastsModal={setPodcastsModal}
        />
      )}
    </>
  );
}

export default PodcastsModal;
