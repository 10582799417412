import React, { Dispatch, SetStateAction, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useIntl } from 'react-intl';
import CreatePodcast from '../CreatePodcast/CreatePodcast';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import SecondaryButton from '../../../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import ModalContainer from '../../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalWrapper from '../../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalHeader from '../../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import './PodcastReader.scss';
import useWindowDimensions from '../../../../../hooks/widthHook';

interface PodcastReaderProps {
  asAdmin: boolean;
  podcast: Podcast;
  setPodcastReader: Dispatch<SetStateAction<boolean>>;
  setPodcastsModal: Dispatch<SetStateAction<boolean>>;
}

function PodcastReader({
  asAdmin,
  podcast,
  setPodcastReader,
  setPodcastsModal,
}: PodcastReaderProps) {
  const [createPodcast, setCreatePodcast] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const { width } = useWindowDimensions();

  return (
    <ErrorBoundary>
      {!createPodcast ? (
        <ModalWrapper higherZIndex>
          <ModalContainer
            styles={{
              backgroundColor: '#FBF5FF',
              borderRadius: '12px',
              padding: '0',
            }}
          >
            <ModalHeader
              mainColor
              withoutBorder
              textHeader={podcast.title}
              textStyles={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
              subTextStyles={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '19px',
                color: '#6C757D',
              }}
              closeSx={{ mt: 1 }}
              onClose={() => {
                setPodcastReader(false);
                setPodcastsModal(false);
              }}
            />
            <div className="PodcastReader__container">
              {width < 1100 && (
                <div className="PodcastReader__mobile-arrow-back">
                  <ArrowBackIcon onClick={() => setPodcastReader(false)} />
                </div>
              )}
              <div className="PodcastReader__top">
                <h3 className="PodcastReader__title">
                  {formatMessage({ id: 'review' })}
                </h3>
                {asAdmin && (
                  <SecondaryButton
                    text="Edit"
                    onClick={() => setCreatePodcast(true)}
                  />
                )}
                <a
                  className="PodcastReader__link"
                  href={podcast.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{formatMessage({ id: 'listenPodcast' })}</span>
                </a>
                {width >= 1100 && (
                  <ArrowBackIcon onClick={() => setPodcastReader(false)} />
                )}
              </div>
              <p
                className="PodcastReader__review"
                dangerouslySetInnerHTML={{
                  __html: podcast.description.replace(/\n/g, '<br />'),
                }}
              />
            </div>
          </ModalContainer>
        </ModalWrapper>
      ) : (
        <CreatePodcast
          podcast={podcast}
          setCreatePodcast={setCreatePodcast}
          setPodcastReader={setPodcastReader}
        />
      )}
    </ErrorBoundary>
  );
}

export default PodcastReader;
