import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { RootState } from '../../redux/store';
import { deleteUserAttachment } from '../../api/userMedia';
import Comments from '../Comments/Comments';
import postDateConvert from '../helpers/postDateConvert';
import { addBrTagInLine } from '../helpers/parseLineBreak';
import { processTextInLink } from '../helpers/linksProcessing';
import { hideSpiner, showSpiner } from '../helpers/setSpinner';
import { getAdvancedVideoPropertiesVideo } from '../helpers/advancedVideoProperties';
import ReportModal from '../shared/ReportModal/ReportModal';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import { SetReactions } from '../shared/SetReactions/SetReactions';
import { TextButton } from '../shared/Buttons/TextButton/TextButton';
import InterestArea from '../shared/Buttons/InterestArea/InterestArea';
import { SharePostModal } from '../shared/SharePostModal/SharePostModal';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import './MediaViewModal.scss';

const modalRoot = document.getElementById("modal-root")!;
interface Props {
  onClose: () => void;
  mediaArr: MediaItem[];
  mediaIndex: number;
  setMediaIndex: (index: number) => void;
  setMediaArr?: (newArr: MediaItem[]) => void;
  isVideoView?: boolean;
}

const MediaViewModal = ({
  onClose,
  mediaIndex,
  mediaArr,
  setMediaIndex,
  isVideoView,
  setMediaArr = () => { },
}: Props) => {
  const { formatMessage } = useIntl();
  const { id } = useSelector((state: RootState) => state.profile);
  const { userPosts } = useSelector((state: RootState) => state.posts);
  const [deleteModal, setDeleteModal] = useState(false);
  const [vidUrl, setVidUrl] = useState('');
  const { someUserId } = useParams();
  const navigate = useNavigate();
  const lan = localStorage.getItem('lang') || 'en';

  const toPost: Post = mediaArr[mediaIndex].toPost;

  useEffect(() => {
    const updatedPost = userPosts.find(post => post.id === toPost.id) || toPost;

    const newArr = [...mediaArr];
    newArr.forEach((media, index) => {
      if (media.toPost?.id === toPost?.id) {
        newArr[index].toPost = updatedPost
      }
    });

    setMediaArr(newArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPosts])

  const [reportModal, setReportModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const slidePhoto = (dir: number) => {
    if (dir === 1 && mediaIndex !== (mediaArr.length - 1)) {
      setMediaIndex(mediaIndex + 1);
    } else if (dir === -1 && mediaIndex !== 0) {
      setMediaIndex(mediaIndex - 1);
    }
  };

  const setVid = async () => {
    const url = await getAdvancedVideoPropertiesVideo(mediaArr[mediaIndex].url);
    setVidUrl(url);
  }

  useEffect(() => {
    setVid()

    document.body.style.overflow = 'hidden';


    const htmlTag = document.getElementById('html-root')
    return () => {
      document.body.style.overflow = 'visible';
      if(htmlTag !== null){
        htmlTag.classList.remove('html-hidden')
      }
    };
  }, []);
  
  useEffect(() => {
    const htmlTag = document.getElementById('html-root')
    if(htmlTag !== null){
      htmlTag.classList.add('html-hidden');
    };
  })

  useEffect(() => {
    vidUrl === '' ? showSpiner() : hideSpiner();
  }, [vidUrl]);

  return (
    createPortal(
      <div className='media-view'>
        <div className='media-view__wrapper'>
          <div className='media-view__inner'>

            <div className='media-view__media'>
              <button
                type='button'
                className='media-view__close'
                onClick={onClose}
              >
                <CloseIcon />
              </button>

              {someUserId === id && (
                <button
                  type='button'
                  className='media-view__header-btn media-view__mobile-delete-btn'
                  onClick={(e) => { setDeleteModal(true) }}
                >
                  <DeleteTwoToneIcon />
                </button>
              )}

              {mediaIndex !== 0 && (
                <button
                  type='button'
                  className='media-view__arrow-left' onClick={() => slidePhoto(-1)}>
                  <ChevronLeftIcon />
                </button>
              )}

              {<div className="media-view__media-wrapper">
                {isVideoView ? (
                  <video
                    id="video"
                    className="media-view__video"
                    preload="none"
                    controls
                    src={vidUrl}
                    muted
                    playsInline
                  />
                ) : (
                  <img
                    className="media-view__img"
                    src={mediaArr[mediaIndex].url}
                    alt="media"
                  />
                )}
                <section id="spiner">
                  <div id="spiner-content"></div>
                </section>
              </div>
              }
              {mediaIndex !== mediaArr.length - 1 && (
                <div className='media-view__arrow-right' onClick={() => slidePhoto(1)} >
                  <ChevronRightIcon />
                </div>
              )}
            </div>

            <div className='media-view__post'>
              <div className='media-view__post-scroll'>
                <div
                  className='media-view__header'
                  style={{
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                  }}
                >
                  <img
                    className='media-view__header-avatar'
                    src={toPost.owner.profileAvatar}
                    alt="avatar"
                  />
                  <div className='media-view__header-info'>
                    <div className='media-view__header-name'>
                      {toPost.owner.firstName} {toPost.owner.lastName} {toPost.owner.organizationName}
                    </div>
                    <div className='media-view__header-date'>
                      {postDateConvert(toPost.created)}
                    </div>

                    {someUserId === id && (
                      <button
                        type='button'
                        className='media-view__header-btn'
                        onClick={(e) => { setDeleteModal(true) }}
                      >
                        <DeleteTwoToneIcon />
                      </button>
                    )}


                  </div>
                </div>
                
                <div
                  style={{
                    display: 'flex',
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                  }}
                >
                  <InterestArea post={toPost} insideModal />
                </div>
                

                <div className='media-view__descr'>
                  {processTextInLink(addBrTagInLine(toPost.description))}
                </div>

                {toPost.showModal && (
                  <div className="media-view__body-text-modal">
                    <MainButton text={formatMessage({ id: 'btnOpenModal' })} onClick={() => {
                      navigate(`?from-submit=true`);
                    }} bigPadding />
                  </div>
                )}

                <SetReactions
                  insideModal
                  onlyComments
                  post={toPost}
                  page={window.location.href.includes('/home') ? 'home' : 'profile'}
                />

                {(
                  <div className="media-view__actions">
                    <TextButton
                      icon={
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_3656_35048)">
                            <path d="M14.778 0.585C14.8462 0.630637 14.9022 0.692369 14.9409 0.764746C14.9796 0.837123 14.9999 0.917918 15 1V8.5C15 8.59985 14.9701 8.69741 14.9141 8.78011C14.8581 8.86281 14.7787 8.92685 14.686 8.964L14.5 8.5L14.686 8.964L14.683 8.965L14.677 8.968L14.654 8.977C14.5225 9.02925 14.3902 9.07926 14.257 9.127C13.993 9.222 13.626 9.35 13.21 9.477C12.394 9.729 11.331 10 10.5 10C9.653 10 8.952 9.72 8.342 9.475L8.314 9.465C7.68 9.21 7.14 9 6.5 9C5.8 9 4.862 9.23 4.063 9.477C3.70526 9.58849 3.35078 9.71021 3 9.842V16C3 16.1326 2.94732 16.2598 2.85355 16.3536C2.75979 16.4473 2.63261 16.5 2.5 16.5C2.36739 16.5 2.24021 16.4473 2.14645 16.3536C2.05268 16.2598 2 16.1326 2 16V1C2 0.867392 2.05268 0.740215 2.14645 0.646447C2.24021 0.552678 2.36739 0.5 2.5 0.5C2.63261 0.5 2.75979 0.552678 2.85355 0.646447C2.94732 0.740215 3 0.867392 3 1V1.282C3.226 1.203 3.496 1.112 3.79 1.022C4.606 0.772 5.67 0.5 6.5 0.5C7.34 0.5 8.024 0.777 8.621 1.019L8.664 1.037C9.286 1.288 9.828 1.5 10.5 1.5C11.2 1.5 12.138 1.27 12.937 1.023C13.3923 0.880812 13.8423 0.72204 14.286 0.547L14.305 0.54L14.309 0.538H14.31" fill="#791186" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3656_35048">
                              <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                          </defs>
                        </svg>
                      }
                      text={formatMessage({ id: 'report' })}
                      onClick={() => { setReportModal(true) }}
                      disabled={toPost.owner.id === id}
                    />
                    <TextButton
                      icon={
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_3656_2060)">
                            <path d="M16.2403 6.43237L10.7402 1.68284C10.2588 1.26706 9.5 1.60459 9.5 2.25047V4.75212C4.48034 4.80959 0.5 5.81562 0.5 10.5727C0.5 12.4927 1.73691 14.3948 3.10416 15.3893C3.53081 15.6997 4.13888 15.3102 3.98156 14.8071C2.56456 10.2754 4.65366 9.07237 9.5 9.00265V11.75C9.5 12.3969 10.2594 12.7329 10.7402 12.3176L16.2403 7.56762C16.5863 7.26881 16.5868 6.73159 16.2403 6.43237Z" fill="#791186" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3656_2060">
                              <rect width="16" height="16" fill="white" transform="translate(0.5 0.5)" />
                            </clipPath>
                          </defs>
                        </svg>
                      }
                      text={formatMessage({ id: 'share' })}
                      onClick={() => { setShareModal(true) }}
                    />
                  </div>
                )}
                <Comments post={toPost} />
              </div>
            </div>
          </div>
        </div>

        {deleteModal && (
          <ModalWrapper width={350}>
            <ModalContainer>
              <ModalHeader
                smallCross
                textHeader={isVideoView ?
                  formatMessage({ id: 'deleteVideoConfirm' }) :
                  formatMessage({ id: 'deletePhotoConfirm' })}
                onClose={() => setDeleteModal(false)}
              />
              <ModalFooter>
                <SecondaryButton
                  text={formatMessage({ id: 'delete' })}
                  onClick={() => {
                    setDeleteModal(false);
                    onClose();
                    deleteUserAttachment(mediaArr[mediaIndex].id);

                    const newArr = [...mediaArr];
                    newArr.splice(mediaIndex, 1)
                    setMediaArr(newArr);
                  }}
                  bigPadding />
                <MainButton
                  bigPadding
                  text={formatMessage({ id: 'cancel' })}
                  onClick={() => { setDeleteModal(false); }}
                />
              </ModalFooter>
            </ModalContainer>
          </ModalWrapper>
        )}

        {
          reportModal && (
            <ReportModal
              postId={toPost.id}
              setReportModal={setReportModal}
            />
            // <ModalWrapper width={350}>
            //   <ModalContainer>
            //     <ModalHeader
            //       smallCross
            //       textHeader={formatMessage({ id: 'reportConfirm' })}
            //       onClose={() => setReportModal(false)}
            //     />
            //     <CustomInput
            //       width='100%'
            //       placeholder={formatMessage({ id: 'reportReason' })}
            //       label={formatMessage({ id: 'reportReasonLabel' })}
            //       onChangeFunc={(value: string) => { setReportReason(value) }}
            //     />
            //     <ModalFooter>
            //       <SecondaryButton
            //         text={formatMessage({ id: 'report' })}
            //         onClick={() => {
            //           setReportModal(false);
            //           reportPost({ postId: toPost.id, reason: reportReason })
            //             .then((res) => {
            //               if (res.status === 400) {
            //                 dispatch(removePost(toPost.id));
            //                 dispatch(removePostFeed(toPost.id));
            //               }
            //             })
            //         }}
            //         bigPadding
            //       />
            //       <MainButton
            //         bigPadding
            //         text={formatMessage({ id: 'cancel' })}
            //         onClick={() => setReportModal(false)}
            //       />
            //     </ModalFooter>
            //   </ModalContainer>
            // </ModalWrapper>
          )
        }

        {shareModal && <SharePostModal post={toPost} setShowSharePostModal={setShareModal} />}
      </div >,
      modalRoot
    )
  )
};

export default MediaViewModal;
