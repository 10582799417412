import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getAllBooks, getAllPodcasts, setOnRelaxRoom } from '../../redux/slices/relaxSlice';
import { AppDispatch } from '../../redux/store';
import ErrorBoundary from '../../components/ErrorBoundary';
import ModalWrapper from '../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../../components/ModalConstructor/ModalFooter/ModalFooter';
import MainButton from '../../components/shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import SpotifyModal from './SpotifyModal/SpotifyModal';
import BooksModal from './BooksModal/BooksModal';
import GamesModal from './GamesModal/GamesModal';
import { ReactComponent as HandClick } from '../../images/cursorHand.svg';
import relaxRoom from '../../images/relaxRoomBG.jpg';
import { useIntl } from 'react-intl';
import useWindowDimensions from '../../hooks/widthHook';
import sprite from '../../images/sprite.svg';
import './RelaxRoomPage.scss';

function RelaxRoomPage() {
  const { formatMessage } = useIntl();
  const [spotifyModal, setSpotifyModal] = useState<boolean>(false);
  const [booksModal, setBooksModal] = useState<boolean>(false);
  const [gamesModal, setGamesModal] = useState<boolean>(false);
  const [goBackModal, setGoBackModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(getAllBooks());
    dispatch(getAllPodcasts());
  }, [dispatch]);

  return (
    <div className="RelaxRoomPage">
      <ErrorBoundary forRelaxPage>
        <div className="RelaxRoomPage__wrapper">
          <img
            className="RelaxRoomPage__img"
            src={relaxRoom}
            alt="Relax Room"
          />

          <div className="RelaxRoomPage__btnWrapper">
            <button
              className="RelaxRoomPage__spotify"
              type="button"
              onClick={() => setSpotifyModal(true)}
            >
              {width >= 1100 ? (
                <HandClick />
              ) : (
                <div className="RelaxRoomPage__btnContainer">
                  <svg className="RelaxRoomPage__btnIcon">
                    <use href={sprite + '#icon-spotify'}></use>
                  </svg>
                  <MainButton
                    styles={{
                      width: 300,
                      height: 70,
                      fontSize: 24,
                      fontWeight: 900,
                      textAlign: 'left',
                      paddingLeft: 65,
                      // textTransform: 'uppercase',
                    }}
                    text={formatMessage({ id: 'listen' })}
                    onClick={() => setSpotifyModal(true)}
                  />
                </div>
              )}
            </button>
            <button
              className="RelaxRoomPage__games"
              type="button"
              onClick={() => setGamesModal(true)}
            >
              {width >= 1100 ? (
                <HandClick />
              ) : (
                <div className="RelaxRoomPage__btnContainer">
                  <svg className="RelaxRoomPage__btnIcon">
                    <use href={sprite + '#icon-game-cube'}></use>
                  </svg>
                  <MainButton
                    styles={{
                      width: 300,
                      height: 70,
                      fontSize: 24,
                      fontWeight: 900,
                      textAlign: 'left',
                      paddingLeft: 65,
                      // textTransform: 'uppercase',
                    }}
                    text={formatMessage({ id: 'games' })}
                    onClick={() => setGamesModal(true)}
                  />
                </div>
              )}
            </button>
            <button
              className="RelaxRoomPage__books"
              type="button"
              onClick={() => setBooksModal(true)}
            >
              {width >= 1100 ? (
                <HandClick />
              ) : (
                <div className="RelaxRoomPage__btnContainer">
                  <svg className="RelaxRoomPage__btnIcon">
                    <use href={sprite + '#icon-books'}></use>
                  </svg>
                  <MainButton
                    styles={{
                      width: 300,
                      height: 70,
                      fontSize: 24,
                      fontWeight: 900,
                      textAlign: 'left',
                      paddingLeft: 65,
                      // textTransform: 'uppercase',
                    }}
                    text={formatMessage({ id: 'books' })}
                    onClick={() => setBooksModal(true)}
                  />
                </div>
              )}
            </button>
            <button
              className="RelaxRoomPage__goBack"
              type="button"
              onClick={() => setGoBackModal(true)}
            >
              {width >= 1100 ? (
                <HandClick />
              ) : (
                <MainButton
                  styles={{
                    width: 300,
                    height: 70,
                    fontSize: 24,
                    fontWeight: 900,
                  }}
                  text={formatMessage({ id: 'returnToWizwork' })}
                  onClick={() => setGoBackModal(true)}
                />
              )}
            </button>
          </div>
        </div>
        {spotifyModal && <SpotifyModal setSpotifyModal={setSpotifyModal} />}
        {gamesModal && <GamesModal setGamesModal={setGamesModal} />}
        {booksModal && <BooksModal setBooksModal={setBooksModal} />}
        {goBackModal && (
          <ModalWrapper width={width >= 1100 ? 350 : 300}>
            <ModalContainer>
              <ModalHeader
                textHeader={formatMessage({ id: 'returnToWizWorkConfirm' })}
                withCloseButton={false}
              />
              <ModalFooter>
                <SecondaryButton
                  styles={{ width: 143 }}
                  text={formatMessage({ id: 'yesReturn' })}
                  onClick={() => {
                    dispatch(setOnRelaxRoom(false));
                    navigate('/my-profile', { replace: true });
                  }}
                />
                <MainButton
                  styles={{ width: 143 }}
                  text={formatMessage({ id: 'noStay' })}
                  onClick={() => setGoBackModal(false)}
                />
              </ModalFooter>
            </ModalContainer>
          </ModalWrapper>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default RelaxRoomPage;
