import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { renderGroupPosts } from '../../../redux/slices/groupsSlice';
import {
  getBookmarkedPosts,
  getFeedPosts,
  getPosts,
  setEditedPost,
  setHasNewPosts,
  setPosts,
  setScrollFeedPos,
  setTypeOfNewPost,
  setCounterFeedSkip
} from '../../../redux/slices/postsSlice';
import {
  archivePostType,
  getAllFakes,
  resetArchivePostTypeStatus,
} from '../../../redux/slices/adminSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { Posts } from '../../Posts/Posts';
import ErrorBoundary from '../../ErrorBoundary';
import ShowMoreAds from '../../ShowMoreAds/ShowMoreAds';
import PostCreation from '../../PostCreation/PostCreation';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import SignUpSuccessModal from '../../SignUpSuccessModal';
import MainButton from '../../shared/Buttons/MainButton/MainButton';
import AddNewPostTypeModal from '../../AddNewPostTypeModal/AddNewPostTypeModal';
import { getTitleTypeOfPost } from '../../helpers/getTitleTypeOfPost';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import interestAreas from '../../../images/interest-areas.svg';
import photoVideo from '../../../images/add-photo-video.svg';
import useWindowDimensions from '../../../hooks/widthHook';
import pencil from '../../../images/pencil.svg';
import './MyFeed.scss';
import { getBrowserName } from '../../helpers/getBrowserName';
import Spinner from '../../Spinner';
import { getAllChats } from '../../../redux/slices/chatSlice';
import SignUpMailModal from '../../SignUpMailModal';
import SignUpSettingModal from '../../SignUpSettingModal';
import FormModal from '../../FormModal/FormModal';

type Props = {
  type: 'home' | 'profile' | 'saved' | 'groups';
};

export const MyFeed: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { id, isAdmin, profileAvatar } = useSelector(
    (state: RootState) => state.profile,
  );
  const {
    bookmarkedPosts,
    feedPosts,
    userPosts,
    totalPosts,
    groupPosts,
    bookmarkedPostCount,
    hasNewPosts,
    countFeedSkip,
    scrollFeedPos
  } = useSelector((state: RootState) => state.posts);
  const lan = localStorage.getItem('lang') || 'en';

  const { postTypes } = useSelector(
    (state: RootState) => state.app,
  );
  const { archivePostTypeStatus } = useSelector(
    (state: RootState) => state.admin,
  );
  const { signUpSuccessModal, signUpMailModal, signUpSettingModal } = useSelector(
    (state: RootState) => state.auth,
  );

  const [countGroupSkip, setCountGroupSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [addNewPostTypeModal, setAddNewPostTypeModal] =
    useState<boolean>(false);
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [archiveLoader, setArchiveLoader] = useState<boolean>(false);
  const [archivePostTypeId, setArchivePostTypeId] = useState<number>(0);

  const [searchParams] = useSearchParams() || '';
  const skipPosts = searchParams.get('skip') || '0';
  const setPostCreation = searchParams.get('create-post') || '';
  const formModal = searchParams.get('from-submit') || '';
  const setPostEdit = searchParams.get('edit-post') || '';

  const typeId = searchParams.get('typeId') || '';
  const interestId = searchParams.get('interestId') || '';
  const groupId = searchParams.get('groupId') || '';
  const filterId = typeId || interestId || groupId;

  const navigate = useNavigate();
  let location = useLocation();
  const { someUserId } = useParams();

  const { width } = useWindowDimensions();
  const browserName = getBrowserName(navigator.userAgent);

  useEffect(() => {
    dispatch(getAllChats()); 
    if (isAdmin) {
      dispatch(getAllFakes());
    }

    if (type !== 'profile') {
      searchParams.set('skip', `0`);

      navigate(`?${searchParams.toString()}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === 'profile' && currentPage !== 1) {
      dispatch(
        getPosts({ userId: someUserId || id, take: 5, skip: (currentPage - 1) * 5 }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (type === 'profile' && someUserId !== undefined) {
      setCurrentPage(1);
      dispatch(setPosts([]));
      dispatch(getPosts({ userId: someUserId || id, take: 5, skip: 0 }));
    }
  }, [dispatch, id, someUserId, type]);

  useEffect(() => {
    if (type === 'saved') {
      dispatch(setHasNewPosts(false));
      dispatch(getBookmarkedPosts(+skipPosts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipPosts]);

  useEffect(() => {
    if (type === 'home') {
      // if (feedPosts.length >= countFeedSkip) {
        dispatch(getFeedPosts(countFeedSkip));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countFeedSkip]);

  useEffect(() => {
    if (type === 'groups') {
      if (typeId) {
        dispatch(renderGroupPosts({ typeId, take: 5, skip: countGroupSkip }));
      }
      if (interestId) {
        dispatch(renderGroupPosts({ interestId, take: 5, skip: countGroupSkip }));
      }
      if (groupId) {
        dispatch(renderGroupPosts({ groupId, take: 5, skip: countGroupSkip }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countGroupSkip]);

  useEffect(() => {
    setCountGroupSkip(0);
    if (type === 'groups') {
      if (typeId) {
        dispatch(renderGroupPosts({ typeId, take: 5, skip: 0 }));
      }

      if (interestId) {
        dispatch(renderGroupPosts({ interestId, take: 5, skip: 0 }));
      }

      if (groupId) {
        dispatch(renderGroupPosts({ groupId, take: 5, skip: 0 }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterId]);

  useEffect(() => {
    if(type === "home"){
      window.scrollTo(0, scrollFeedPos)
    }
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPosts, feedPosts, groupPosts]);

  useEffect(() => {
    if (archivePostTypeStatus === 'loading') {
      setArchiveLoader(true);
    }
    if (archivePostTypeStatus === 'success') {
      setArchiveLoader(false);
      dispatch(resetArchivePostTypeStatus());
      setArchiveModal(false);
    }
    if (archivePostTypeStatus === 'rejected') {
      setArchiveLoader(false);
      dispatch(resetArchivePostTypeStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivePostTypeStatus]);

  const closePostCreation = (typeOfAction: string) => {
    searchParams.delete(`${typeOfAction}-post`);
    navigate(`?${searchParams.toString()}`, { replace: true });
    dispatch(setEditedPost(null));
  };

  const closeFormModal = () => {
    searchParams.delete(`from-submit`);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleClick = (typeOfPost: TypeOfPost, typeOfAction: string) => {
    searchParams.set(`${typeOfAction}-post`, 'on');

    if (setPostCreation === 'on') {
      searchParams.delete(`${typeOfAction}-post`);
    } else {
      searchParams.set(`${typeOfAction}-post`, 'on');
    }

    navigate(`?${searchParams.toString()}`, { replace: true });
    dispatch(setTypeOfNewPost(typeOfPost));
  };

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const windowHeight = document.body.offsetHeight;

    if(location.pathname === '/home' && !(window.scrollY < 150 && scrollFeedPos >= 150)){
      dispatch(setScrollFeedPos(window.scrollY))
    }

    let bottom = Math.ceil(scrollPosition + 5) >= windowHeight;

    if (browserName === 'Apple Safari') {
      bottom = Math.ceil(scrollPosition) === windowHeight
        || Math.ceil(scrollPosition - 1) === windowHeight;
    }
    if (bottom && type === 'home') {
      dispatch(setCounterFeedSkip(countFeedSkip + 5));
      window.removeEventListener('scroll', handleScroll);
    }

    if (bottom && type === 'profile' && userPosts.length < totalPosts) {
      dispatch(setHasNewPosts(true));
      setCurrentPage(prev => prev + 1);
      window.removeEventListener('scroll', handleScroll);
    }

    if (bottom && type === 'profile' && userPosts.length === totalPosts) {
      dispatch(setHasNewPosts(false));
    }

    if (bottom && type === 'groups' && groupPosts.length < totalPosts) {
      dispatch(setHasNewPosts(true));
      setCountGroupSkip(prev => prev + 5);
      window.removeEventListener('scroll', handleScroll);
    }

    if (bottom && type === 'groups' && groupPosts.length === totalPosts) {
      dispatch(setHasNewPosts(false));
    }
  }

  const onArchivedPostType = () => {
    dispatch(archivePostType(archivePostTypeId));
  };

  const morePosts = () => {
    searchParams.set('skip', `${+skipPosts + 5}`);

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  return (
    <ErrorBoundary forHomePage={type === 'home' ? true : false}>
      <div className="my-feed">
        <ShowMoreAds type={type} />

        {type !== 'saved' && (
          <>
            {(someUserId === id || !someUserId) && (
              <>
                <div className="my-feed__section create-block">
                  <h2 className="my-feed__section-title">
                    {formatMessage({ id: 'create' })}
                  </h2>

                  <div className="create-block__row">
                    {postTypes
                      ?.filter((postType: any) => !postType.isArchived)
                      ?.map((postType: any) => {
                        if (postType.id !== 5 && postType.id !== 6) {
                          return (
                            <div
                              key={postType.id}
                              className="create-block__button-container"
                            >
                              <button
                                data-disabled={
                                  location.pathname === '/groups' &&
                                  typeId !== '' &&
                                  +typeId !== postType.id
                                }
                                className="create-block__button"
                                onClick={() => handleClick(postType, 'create')}
                              >
                                <span className="create-block__button-text">
                                  {postType.id < 6
                                    ? formatMessage({
                                      id: getTitleTypeOfPost(
                                        postType.typeName,
                                      ),
                                    })
                                    : postType.typeName}
                                </span>
                              </button>
                              {isAdmin && postType.id > 6 ? (
                                <button
                                  disabled={
                                    location.pathname === '/groups' &&
                                      typeId !== '' &&
                                      +typeId !== postType.id
                                      ? true
                                      : false
                                  }
                                  className="create-block__button-archive"
                                  onClick={() => {
                                    setArchivePostTypeId(postType.id);
                                    setArchiveModal(true);
                                  }}
                                >
                                  <ArchiveTwoToneIcon />
                                </button>
                              ) : null}
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>

                  {isAdmin ? (
                    <div className="create-block__admin-row">
                      <MainButton
                        text={formatMessage({ id: 'addNewPostType' })}
                        onClick={() => setAddNewPostTypeModal(true)}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="my-feed__section create-post-block">
                  <h2 className="my-feed__section-title">
                    {formatMessage({ id: 'createPost' })}
                  </h2>

                  <div className="create-post-block__row">
                    <div
                      className="create-post-block__details"
                      style={{
                        flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                      }}
                    >
                      <div className="create-post-block__avatar-container">
                        <img
                          className="create-post-block__avatar"
                          src={profileAvatar}
                          alt="create-post-img"
                        />
                      </div>
                      <label
                        className="create-post-block__input-wrapper"
                        style={{
                          flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                        }}
                      >
                        <img
                          src={pencil}
                          alt=""
                          className="create-post-block__pencil"
                          style={{
                            left: (lan === 'ar' ? 'none' : '18.5px'),
                            right: (lan === 'ar' ? '18.5px' : 'none')
                          }}
                        />
                        <span
                          className="create-post-block__placeholder"
                          style={{
                            left: (lan === 'ar' ? 'none' : '42px'),
                            right: (lan === 'ar' ? '42px' : 'none')
                          }}
                        >
                          {formatMessage({ id: 'startPost' })}
                        </span>

                        <div
                          className="create-post-block__input"
                          style={{
                            borderRadius: width >= 1100 ? '50px' : (lan === 'ar' ? '0 50px 50px 0' : '50px 0 0 50px')
                          }}
                        ></div>

                        {/* <input
                          type="button"
                          className="create-post-block__input"
                          placeholder={formatMessage({ id: 'startPost' })}
                          onClick={() => handleClick(
                            location.pathname === '/groups' && typeId !== ''
                              ? postTypes.find((type: any) => +type.id === +typeId)
                              : { typeName: 'DEFAULT', id: 5 },
                            'create'
                          )}
                        /> */}
                        <div className="create-post-block__post-button-mobile">
                          <MainButton
                            styles={{
                              borderRadius: lan === 'ar' ? '16px 0 0 16px' : '0px 16px 16px 0px'
                            }}
                            text={formatMessage({ id: 'toPost' })}
                            onClick={() =>
                              handleClick(
                                location.pathname === '/groups' && typeId !== ''
                                  ? postTypes.find(
                                    (type: any) => +type.id === +typeId,
                                  )
                                  : { typeName: 'DEFAULT', id: 5 },
                                'create',
                              )
                            }
                          />
                        </div>
                      </label>
                    </div>

                    <div
                      className="create-post-block__more"
                      style={{
                        flexDirection: (lan === 'ar' ? 'row-reverse' : 'row'),
                        padding: width >= 1100 ? (lan === 'ar' ? '0 64px 0 0' : '0 0 0 64px') : (lan === 'ar' ? '0 38px 0 0' : '0 0 0 38px')
                      }}
                    >
                      <div
                        className="create-post-block__item-wrapper"
                        style={{
                          flexDirection: (width >= 1100 ? (lan === 'ar' ? 'row-reverse' : 'row') : 'column'),
                        }}
                      >
                        <div
                          className="create-post-block__item"
                          onClick={() =>
                            handleClick(
                              location.pathname === '/groups' && typeId !== ''
                                ? postTypes.find(
                                  (type: any) => +type.id === +typeId,
                                )
                                : { typeName: 'DEFAULT', id: 5 },
                              'create',
                            )
                          }
                          style={{
                            flexDirection: (lan === 'ar' ? 'row-reverse' : 'row'),
                          }}
                        >
                          <img src={photoVideo} alt="" />
                          <span>{formatMessage({ id: 'addAttachment' })}</span>
                        </div>

                        {/* <div
                          className="create-post-block__item"
                          onClick={() =>
                            handleClick(
                              location.pathname === '/groups' && typeId !== ''
                                ? postTypes.find(
                                  (type: any) => +type.id === +typeId,
                                )
                                : { typeName: 'DEFAULT', id: 5 },
                              'create',
                            )
                          }
                          style={{
                            flexDirection: (lan === 'ar' ? 'row-reverse' : 'row'),
                          }}
                        >
                          <img src={interestAreas} alt="" />
                          <span>
                            {formatMessage({ id: 'selectInterests' })}
                          </span>
                        </div> */}
                      </div>

                      <div className="create-post-block__post-button-desctop">
                        <MainButton
                          text={formatMessage({ id: 'post' })}
                          onClick={() =>
                            handleClick(
                              location.pathname === '/groups' && typeId !== ''
                                ? postTypes.find(
                                  (type: any) => +type.id === +typeId,
                                )
                                : { typeName: 'DEFAULT', id: 5 },
                              'create',
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {signUpSuccessModal && <SignUpSuccessModal />}
        {signUpSettingModal && <SignUpSettingModal />}
        {signUpMailModal && <SignUpMailModal />}

        {(type === 'home' && feedPosts?.length) ||
          (type === 'profile' && userPosts?.length) ||
          (type === 'saved' && bookmarkedPosts?.length) ||
          (type === 'groups' && groupPosts?.length) ? (
          <>
            <Posts type={type} />
            {hasNewPosts && <Spinner />}
          </>
        ) : (
          <h2 className="my-feed__no-posts">
            {formatMessage({ id: 'noPosts' })}
          </h2>
        )}

        {/* {
          type === 'home' &&
          <h2 className="my-feed__no-posts">
            {formatMessage({ id: 'youHaveRead' })}<br />
            {formatMessage({ id: 'followOthers' })}
          </h2>
        } */}

        {setPostCreation && (
          <PostCreation close={closePostCreation} type={'create'} />
        )}
        {setPostEdit && (
          <PostCreation close={closePostCreation} type={'edit'} />
        )}
        {addNewPostTypeModal && (
          <AddNewPostTypeModal close={setAddNewPostTypeModal} />
        )}
        {formModal && (
          <FormModal close={closeFormModal} />
        )}
        {archiveModal && (
          <ConfirmModal
            withCloseIcon
            contentId="archivePostType"
            secondaryButtonTextId="archive"
            secondaryButtonAction={() => onArchivedPostType()}
            mainButtonAction={() => setArchiveModal(false)}
            pStyles={{ width: '260px', margin: '0 21px' }}
            loader={archiveLoader}
          />
        )}

        {type === 'saved' &&
          bookmarkedPostCount > +skipPosts + 5 &&
          bookmarkedPosts?.length > 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              background: '#F5F5F5',
              paddingTop: '20px',
            }}
            onClick={morePosts}
          >
            <MainButton
              text={formatMessage({ id: 'showMore' })}
              type="submit"
              styles={
                width < 1100
                  ? {
                    width: '90%',
                    marginRight: '0',
                    position: 'absolute',
                    left: '24px',
                    background: '#E9E9E9',
                    color: '#791186',
                  }
                  : {
                    width: '400px',
                    background: '#E9E9E9',
                    color: '#791186',
                  }
              }
            />
          </div>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};
