import axios from 'axios';
import { API, customAxios } from '../api/api';
import { Buffer } from 'buffer';

const getBooks_API = `${API}/api/relax/get-all-books`; //get
const createBook_API = `${API}/api/relax/create-new-book`; //post
const editBook_API = `${API}/api/relax/edit-book`; //put
const deleteBook_API = `${API}/api/relax/delete-book`; //delete

const getPodcasts_API = `${API}/api/relax/get-all-podcasts`; //get
const createPodcast_API = `${API}/api/relax/create-new-podcast`; //post
const editPodcast_API = `${API}/api/relax/edit-podcast`; //put
const deletePodcast_API = `${API}/api/relax/delete-podcast`; //delete

const Auth_API = 'https://accounts.spotify.com/api/token'; //spotify
const Spotify_API = 'https://api.spotify.com/v1/'; //spotify

// spotify creds
const credentialSpotify = {
  ClientId: 'a387db845b5b40e4bca3a245b9b27be6',
  ClientSecret: '31acd60c66cb4a558aed12c8d3e024e4',
};

// spotify config
const authConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic ' + Buffer
      .from(credentialSpotify.ClientId + ':' + credentialSpotify.ClientSecret)
      .toString('base64'),
  },
};

const spotifyConfig = (token: string) => ({
  headers: {
    'Authorization': 'Bearer ' + token
  },
});
//=====================================================//
export async function getBooksAPI() {
  return await customAxios.get(getBooks_API);
}
export async function createBookAPI(data: any) {
  return await customAxios.post(createBook_API, data)
}
export async function editBookAPI(data: any) {
  return await customAxios.put(editBook_API, data)
}
export async function deleteBookAPI(bookId: number) {
  return await customAxios.delete(deleteBook_API, { data: { bookId } });
}
//=====================================================//
export async function getPodcastsAPI() {
  return await customAxios.get(getPodcasts_API);
}
export async function createPodcastAPI(data: any) {
  return await customAxios.post(createPodcast_API, data)
}
export async function editPodcastAPI(data: any) {
  return await customAxios.put(editPodcast_API, data)
}
export async function deletePodcastAPI(podcastId: number) {
  return await customAxios.delete(deletePodcast_API, { data: { podcastId } });
}
//=====================================================//
export async function getSpotifyAPI() {
  return await axios.post(Auth_API, 'grant_type=client_credentials', authConfig);
}

export async function getTopPlaylistsByCountryAPI(token: string) {
  return await axios.get(
    `${Spotify_API}search?q=top%2050&type=playlist&limit=50`,
    spotifyConfig(token),
  );
};

export async function getMusicGenresAPI(token: string) {
  return await axios.get(
    `${Spotify_API}browse/categories?locale=sv_US`,
    spotifyConfig(token),
  );
};

export async function getPlaylistsAPI(token: string, genreId: string) {
  return await axios.get(
    `${Spotify_API}browse/categories/${genreId}/playlists?limit=50`,
    spotifyConfig(token),
  );
};

export async function getShowsAPI(token: string, search: string) {
  const searchValue = search.toLowerCase().split(' ').join('%20');
  return await axios.get(
    `${Spotify_API}search?q=${searchValue}&type=show&market=US&limit=30`,
    spotifyConfig(token),
  );
};

export async function getShowEpisodesAPI(token: string, showId: string) {
  return await axios.get(
    `${Spotify_API}shows/${showId}/episodes?market=US&limit=50`,
    spotifyConfig(token),
  );
};

export async function getNextOrPrevEpisodesAPI(token: string, link: string) {
  return await axios.get(link, spotifyConfig(token));
};
