export const IFrame_API = '//open.spotify.com/embed/';
export const iframeAllows = 'autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture';
export const initialBookCover: AttachmentBook = {
  type: 'PHOTO',
  url: '',
};

export const initialPodcastCover: AttachmentPodcast = {
  type: 'PHOTO',
  url: '',
};

export const defaultPlaylist = {
  byCountry: '37i9dQZEVXbMDoHDwVN2tF',
  byGanre: '37i9dQZF1DWZqd5JICZI0u',
};

export const topPlaylist: string[] = [
  '37i9dQZF1DX4JAvHpjipBk',
  '37i9dQZF1DX10zKzsJ2jva',
  '37i9dQZF1DXcBWIGoYBM5M',
  '5ABHKGoOzxkaa28ttQV9sE',
  '5iwkYfnHAGMEFLiHFFGnP4',
  '37i9dQZF1DWXe9gFZP0gtP',
];

export const topPodcasts: string[] = [
  '4IbS9vnPhqAhWRwLUv5d0i',
  '5c26B28vZMN8PG0Nppmn5G',
  '7GWJTpxFaUIMvQtz3ijBg1',
  '0yKEWqaqfaAKlSjYnDuisy',
  '15a2hAGKVl0ZIumA1z1WWV',
  '7Fr4RhLwYlfPOZTlXerEv1',
  '2hAz8kvUg17OVy4rT42Ug4',
  '3YRne613KcHNQP1yv8TrUW',
  '6L47MDMO3xuN2XBed7miEI',
  '0U9S5J2ltMaKdxIfLuEjzE',
  '2VRS1IJCTn2Nlkg33ZVfkM',
  '2SZX2oyIXVvyqEtjQ6C6z5',
  '08F60fHBihlcqWZTr7Thzc',
  '7z8tSXAARFiF7LvC5lwnwl',
  '5tQhv7b6Qx2XyHB3Cv6Zuw',
  '2q70TaEtdg1iYSb30uI76c',
  '1Tt2uTNBOU5k6HFE7RnCR2',
  '4gKJOrFLXNIdhkVX4wVGEC',
  '2df3LHz7lmoehVhaJt7oZe',
  '10iWk6HV5OnSxSg5G92Maa',
];

export const games: Game[] = [
  {
    id: 25089,
    name: 'Solitaire 2048',
    preview: 'https://www.addictinggames.com/sites/default/files/solitaire-master_0.mp4',
  },
  {
    id: 18170,
    name: 'Bounce Adventure',
    preview: 'https://www.addictinggames.com/sites/default/files/Bounce-Adventure.mp4',
  },
  {
    id: 24865,
    name: 'The Mafia Poker',
    preview: 'https://www.addictinggames.com/sites/default/files/mafia-poker.mp4',
  },
  {
    id: 24748,
    name: 'Logic Magnets',
    preview: 'https://www.addictinggames.com/sites/default/files/logicmagnet275x157.mp4',
  },
  {
    id: 24792,
    name: 'Fast Words',
    preview: 'https://www.addictinggames.com/sites/default/files/fastwordsgame275x157.mp4',
  },
  {
    id: 24895,
    name: 'The Jigsaw Puzzle',
    preview: 'https://www.addictinggames.com/sites/default/files/jigsaw.mp4',
  },
  {
    id: 25169,
    name: 'Pool Shooter',
    preview: 'https://www.addictinggames.com/sites/default/files/poolshootgame.mp4',
  },
  {
    id: 24869,
    name: '21 Blackjack Master',
    preview: 'https://www.addictinggames.com/sites/default/files/solitaire_99.mp4',
  },
  {
    id: 25041,
    name: 'Super Candy Jewels',
    preview: 'https://www.addictinggames.com/sites/default/files/supercandyjewelz275x157.mp4',
  },
  {
    id: 24747,
    name: 'Hidden Objects: Super Thief',
    preview: 'https://www.addictinggames.com/sites/default/files/hidden-objects-super-thief-0.mp4',
  },
  {
    id: 24682,
    name: 'Ring Fall',
    preview: 'https://www.addictinggames.com/sites/default/files/ring-fall.mp4',
  },
  {
    id: 24679,
    name: 'Subway Runner',
    preview: 'https://www.addictinggames.com/sites/default/files/junglerun275x157.mp4',
  },
  {
    id: 24688,
    name: 'Blue Game',
    preview: 'https://www.addictinggames.com/sites/default/files/blue.mp4',
  },
  {
    id: 24642,
    name: 'Balls And Bricks',
    preview: 'https://www.addictinggames.com/sites/default/files/ballsbrickgamevid275x157.mp4',
  },
  {
    id: 23633,
    name: '8 Ball Billiards Classic',
    preview: 'https://www.addictinggames.com/sites/default/files/8-ball-billiards-classic.mp4',
  },
  {
    id: 24896,
    name: 'Ski King',
    preview: 'https://www.addictinggames.com/sites/default/files/skiking275x157.mp4',
  },
  {
    id: 23877,
    name: 'Math Slither',
    preview: 'https://www.addictinggames.com/sites/default/files/solitaire_60.mp4',
  },
  {
    id: 'reversi',
    name: 'Othello Reversi',
    preview: '',
  },
  {
    id: 'chess',
    name: 'Mini Chess',
    preview: '',
  },
  {
    id: 'checkers',
    name: 'Mini Checkers / Draughts',
    preview: '',
  },
];
