import React, { useEffect, useState } from 'react';
import { AppDispatch } from '../../redux/store';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import { useDispatch } from 'react-redux';
import { skipPostFromReport, removePostFromReport } from '../../redux/slices/postsSlice';
import { useIntl } from 'react-intl';

type Props = {
    close: (_: boolean) => void;
};

const FormModal: React.FC<Props> = ({close,}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const actionFunc = async () => {
    return;
  }

  return (
    <ModalWrapper width={350}>
      <ModalContainer>
        <ModalHeader
          textHeader={formatMessage({ id: 'fromHeader' })}
          smallCross onClose={() => close(false)}
        />
        {/* <ModalContainer > */}
          <div className="form-modal__body">
            <div className="form-modal__body-text">
              <p>{formatMessage({ id: 'formModalText' })}</p>
            </div>
          </div>
        {/* </ModalContainer> */}
        <ModalFooter>
          {/* <SecondaryButton
            text={formatMessage({ id: "submit" })}
            onClick={() => actionFunc()}
            bigPadding
          /> */}
          <MainButton text={formatMessage({ id: 'cancel' })} onClick={() => close(false)} bigPadding />
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default FormModal;