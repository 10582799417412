import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { intl } from '../../localization';
import { toast } from 'react-toastify';
import { getPostInterestsAPI } from '../../api/iterests';
import {
  postVoteApi,
  getPostsApi,
  postDeleteAPI,
  editPostAPI,
  createCommentPostAPI,
  deleteCommentPostAPI,
  editCommentPostAPI,
  getMorePostsApi,
  createSubcommentPostAPI,
  likePostAPI,
  bookmarkPostAPI,
  getBookmarkedPostsAPI,
  createPostAPI,
  postReactionAPI,
  getCommentsAPI,
  postShareAPI,
  getPostsReports,
  deleteReportPost,
  ignorReportPost,
  getFeedPostsAPI,
  getReportsReasons,
  getPostSharedAPI,
  getPostReactionsAPI,
  getSubcommentsAPI,
} from '../../api/posts';
import { getLastActivityAPI } from '../../api/user';
import { getPagePosts } from '../../components/helpers/getPosts';
import { startTranslate } from '../../components/helpers/getTraslate';
import { showLoader } from './appSlice';

export interface PostState {
  singlePost: Post[] | [];
  userPosts: Post[] | [];
  feedPosts: Post[] | [];
  reportsPosts: ReportPost[] | [];
  groupPosts: Post[] | [];
  bookmarkedPosts: Post[] | [];
  bookmarkedPostsFeed: BookmarkedPost[] | [],
  totalPosts: number;
  typeOfNewPost: TypeOfPost;
  editedPost: Post | null;
  activity: LastActivity[];
  status: string;
  error: null;
  notifLength: number | null;
  bookmarkedLength: number | null;
  postReportReasons: any[];
  bookmarkedPostCount: number;
  choosenTags: boolean;
  selectedInterests: Chip[];
  hasNewPosts: boolean;
  countFeedSkip: number;
  scrollFeedPos: number;
  
}

const initialState: PostState = {
  singlePost: [],
  userPosts: [],
  feedPosts: [],
  reportsPosts: [],
  groupPosts: [],
  bookmarkedPosts: [],
  bookmarkedPostsFeed: [],
  totalPosts: 0,
  typeOfNewPost: { id: 5, typeName: 'DEFAULT' },
  editedPost: null,
  activity: [],
  status: '',
  error: null,
  notifLength: null,
  bookmarkedLength: null,
  postReportReasons: [],
  bookmarkedPostCount: 0,
  choosenTags: false,
  selectedInterests: [],
  hasNewPosts: true,
  countFeedSkip: 0,
  scrollFeedPos: 0
};

// TRANSLATION

export const getTranslation = createAsyncThunk(
  'posts/getTranslation',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      let postTexts = {
        id: data.postId,
        commentId: data.commentId || 0,
        originalPost: {},
        description: '',
        title: '',
        answers: []
      } as any;

      if (data.translate) {
        if (data.title) {
          const res = await startTranslate(data.title, data.lang);
          postTexts.title = res;
        };

        if (data.description) {
          const res = await startTranslate(data.description, data.lang);
          postTexts.description = res;
        };

        if (data.originalPost?.title) {
          const res = await startTranslate(data.originalPost.title, data.lang);
          postTexts.originalPost.title = res;
        };

        if (data.originalPost?.description) {
          const res = await startTranslate(data.originalPost.description, data.lang);
          postTexts.originalPost.description = res;
        };

        if (data.answers) {
          const translateAnswers = [] as any;
          for (const answere of data.answers) {
            ;
            const res = await startTranslate(answere?.title, data.lang);

            translateAnswers.push({
              ...answere,
              title: res
            })
          };

          postTexts.answers = translateAnswers;
        };
      } else {
        if (data.description) {
          postTexts.description = data.description;
        };

        if (data.title) {
          postTexts.title = data.title;
        };

        if (data.originalPost?.title) {
          postTexts.originalPost.title = data.originalPost?.title;
        };

        if (data.originalPost?.description) {
          postTexts.originalPost.description = data.originalPost?.description;
        };

        if (data.answers) {
          postTexts.answers = data.answers;
        };
      }

      switch (data.type) {
        case 'Post':
          if (window.location.href.includes('/profile')) {
            dispatch(translatePost({ ...postTexts, certainPostPage: 'userPosts' }));
          } else if (window.location.href.includes('/home')) {
            dispatch(translatePost({ ...postTexts, certainPostPage: 'feedPosts' }));
          } else if (window.location.href.includes('/posts')) {
            dispatch(translatePost({ ...postTexts, certainPostPage: 'reportsPosts' }));
          } else if (window.location.href.includes('/post')) {
            dispatch(translatePost({ ...postTexts, certainPostPage: 'singlePost' }));
          } else if (window.location.href.includes('/groups')) {
            dispatch(translatePost({ ...postTexts, certainPostPage: 'groupPosts' }));
          } else if (window.location.href.includes('/activity-log')) {
            dispatch(translatePost({ ...postTexts, certainPostPage: 'bookmaredPosts' }));
          }
          break;
      }

      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// POSTS
export const getPosts = createAsyncThunk(
  'posts/getPosts',
  async function (data: GetUserPost, { dispatch }) {

    try {
      const posts = await getPostsApi(data);

      dispatch(setTotalPosts(posts.posts[1]));
      dispatch(setBookmarkedPostFeed(posts.isBookmarked));

      if (data.skip === 0) {
        dispatch(setPosts(posts.posts[0]));
      } else {
        dispatch(concatPosts(posts.posts[0]));
      }

    } catch (error: any) {
      if (error.response) {
        throw new Error('not working');
      }
    }
  },
);

export const getFeedPosts = createAsyncThunk(
  'posts/getFeedPosts',
  async function (skip: any, { rejectWithValue, dispatch }) {
    try {
      const posts = await getFeedPostsAPI(skip);

      dispatch(setHasNewPosts(!!posts.data.length));

      if (posts.data.length === 0) {
        dispatch(setTotalPosts(0));
      }
      // dispatch(setBookmarkedPostFeed(posts.data.isBookmarked));
      if (skip === 0) {
        dispatch(setFeedPosts(posts.data));
      } else {
        dispatch(loadMorePosts(posts.data));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createPost = createAsyncThunk(
  'posts/createPost',
  async function (data: CreatePost, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const post = await createPostAPI(data);
      dispatch(showLoader(false));

      if (!data.delay) {
        if (window.location.href.includes('/profile')) {
          dispatch(addNewPostProfile(post.data));
        } else if (window.location.href.includes('/home')) {
          dispatch(addNewPostHome(post.data));
        } else if (window.location.href.includes('/groups')) {
          dispatch(addNewPostGroup(post.data));
        }
      }

      if (!post.data) {
        dispatch(showLoader(false));
        throw new Error(intl.intl().formatMessage({ id: 'createPostServerError' }));
      }
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

export const editPost = createAsyncThunk(
  'posts/editPost',
  async function (data: EditPost, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    const { formatMessage } = intl.intl();
    try {
      const post = await editPostAPI(data);

      if (window.location.href.includes('/profile')) {
        dispatch(rerenderPost(post.data));
      } else if (window.location.href.includes('/home')) {
        dispatch(rerenderFeedPost(post.data));
      } else if (window.location.href.includes('/groups')) {
        dispatch(rerenderGroupPost(post.data));
      } else if (window.location.href.includes('/activity-log')) {
        dispatch(rerenderBookmarkedPost(post.data));
      } else if (window.location.href.includes('/post')) {
        dispatch(rerenderSinglePost(post.data));
      }

      dispatch(showLoader(false));
      toast.success(formatMessage({ id: 'successEditedPost' }))
      if (!post.data) {
        dispatch(showLoader(false));
        throw new Error("Can't create new post. Server error");
      }
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

const setLoading = (state: any) => {
  state.status = 'loading';
  state.error = null;
};

const setSuccess = (state: any) => {
  state.status = 'success';
  state.error = null;
};

const setError = (state: any, action: any) => {
  state.status = 'reject';
  state.error = action.payload;
};

export const postVote = createAsyncThunk(
  'posts/postVote',
  async function (data: any, { dispatch }) {
    try {
      const response = await postVoteApi(data.answerId);

      if (response.statusCode === 400) {
        dispatch(removePost(data.postId));
        dispatch(removePostFeed(data.postId));
      } else {
        if (window.location.href.includes('/profile')) {
          dispatch(rerenderPost(response[0][0]));
        } else if (window.location.href.includes('/home')) {
          dispatch(rerenderFeedPost(response[0][0]));
        } else if (window.location.href.includes('/posts')) {
          dispatch(rerenderReportsPost(response[0][0]));
        } else if (window.location.href.includes('/post')) {
          dispatch(rerenderSinglePost(response[0][0]));
        } else if (window.location.href.includes('/groups')) {
          dispatch(rerenderGroupPost(response[0][0]));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(rerenderBookmarkedPost(response[0][0]));
        }
      }

    } catch (error: any) {
      if (error.response) {
        throw new Error('not working at here');
      }
    }
  },
);

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async function (data: any, { rejectWithValue, dispatch }) {
    const { formatMessage } = intl.intl();
    try {
      const response: any = await postDeleteAPI(data.postId);
      dispatch(removeAllReportPosts(data.postId));
      toast.info(formatMessage({ id: 'postDeletedMain' }));
      if (response.status === 200) {
        dispatch(removePost(data.postId));
        dispatch(removePostFeed(data.postId));
        dispatch(removePostGroups(data.postId));
        dispatch(decreaseSharesCounter(data.postId));
        dispatch(removeSinglePost());

      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const postReaction = createAsyncThunk(
  'posts/postReaction',
  async function (data: any, { dispatch }) {
    try {
      const response = await postReactionAPI(data.elType, data.postId, data?.anotherUserId);

      if (response.statusCode === 400) {
        dispatch(removePost(data.postId));
        dispatch(removePostFeed(data.postId));
      } else {
        if (window.location.href.includes('/profile')) {
          dispatch(rerenderReactions({ ...data, certainPagePosts: 'userPosts' }));
        } else if (window.location.href.includes('/home')) {
          dispatch(rerenderReactions({ ...data, certainPagePosts: 'feedPosts' }));
        } else if (window.location.href.includes('/groups')) {
          dispatch(rerenderReactions({ ...data, certainPagePosts: 'groupPosts' }));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(rerenderReactions({ ...data, certainPagePosts: 'bookmaredPosts' }));
        } else if (window.location.href.includes('/posts')) {
          dispatch(rerenderReactions({ ...data, certainPagePosts: 'reportsPosts' }));
        } else if (window.location.href.includes('/post')) {
          dispatch(rerenderReactions({ ...data, certainPagePosts: 'singlePost' }));
        }
      }
    } catch (error: any) {
      if (error.response) {
        throw new Error('not working at here');
      }
    }
  },
);

// ========Comments & Subcomments===============================

export const createComment = createAsyncThunk(
  'posts/createComment',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await createCommentPostAPI(data);
      if (response.status === 201) {
        dispatch(addNewComments({ comment: response.data, postId: data }))
        dispatch(increaseCommentCounter({ comment: response.data, postId: data }));
      }

      if (response.status === 400) {
        dispatch(removePost(data.id));
        dispatch(removePostFeed(data.id));
      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const editComment = createAsyncThunk(
  'posts/editComment',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await editCommentPostAPI(data);

      if (response.status === 200) {
        if (window.location.href.includes('/profile')) {
          dispatch(editCommentReducer({ ...data, certainPagePosts: 'userPosts' }))
        } else if (window.location.href.includes('/home')) {
          dispatch(editCommentReducer({ ...data, certainPagePosts: 'feedPosts' }))
        } else if (window.location.href.includes('/posts')) {
          dispatch(editCommentReducer({ ...data, certainPagePosts: 'reportsPosts' }))
        } else if (window.location.href.includes('/post')) {
          dispatch(editCommentReducer({ ...data, certainPagePosts: 'singlePost' }))
        } else if (window.location.href.includes('/groups')) {
          dispatch(editCommentReducer({ ...data, certainPagePosts: 'groupPosts' }))
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(editCommentReducer({ ...data, certainPagePosts: 'bookmaredPosts' }))
        }
      }

      if (response.status === 400) {
        if (window.location.href.includes('/profile')) {
          dispatch(deletePostComment({ commentId: data.id }));
        } else if (window.location.href.includes('/home')) {
          dispatch(deleteFeedComment({ commentId: data.id }));
        } else if (window.location.href.includes('/post')) {
          dispatch(deleteSinglePostComment({ commentId: data.id }));
        } else if (window.location.href.includes('/groups')) {
          dispatch(deleteGroupComment({ commentId: data.id }));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(deleteBookmarkedComment({ commentId: data.id }));
        }
      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteComment = createAsyncThunk(
  'posts/deleteComment',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await deleteCommentPostAPI(data);

      if (response.status === 200) {
        if (window.location.href.includes('/profile')) {
          dispatch(deletePostComment(data));
        } else if (window.location.href.includes('/home')) {
          dispatch(deleteFeedComment(data));
        } else if (window.location.href.includes('/posts')) {
          dispatch(deleteReportsComment(data));
        } else if (window.location.href.includes('/post')) {
          dispatch(deleteSinglePostComment(data));
        } else if (window.location.href.includes('/groups')) {
          dispatch(deleteGroupComment(data));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(deleteBookmarkedComment(data));
        }
      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createSubcomment = createAsyncThunk(
  'posts/createSubcomment',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await createSubcommentPostAPI(data);

      if (response.status === 201) {
        dispatch(addNewSubcomments({ subcomment: response.data, commentId: data }));
      }

      if (response.status === 400) {
        if (window.location.href.includes('/profile')) {
          dispatch(deletePostComment({ commentId: data.id }));
        } else if (window.location.href.includes('/home')) {
          dispatch(deleteFeedComment({ commentId: data.id }));
        } else if (window.location.href.includes('/post')) {
          dispatch(deleteSinglePostComment({ commentId: data.id }));
        } else if (window.location.href.includes('/groups')) {
          dispatch(deleteGroupComment({ commentId: data.id }));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(deleteBookmarkedComment({ commentId: data.id }));
        }
      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getComments = createAsyncThunk(
  'posts/getComments',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await getCommentsAPI(data);
      data.skip === 0
        ? dispatch(setComments(response))
        : dispatch(moreComments(response));

      if (!response.data) {
        throw new Error(intl.intl().formatMessage({ id: 'getCommentsServerError' }));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getSubComments = createAsyncThunk(
  'posts/getSubComments',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await getSubcommentsAPI(data);
      data.skip === 0
        ? dispatch(setComments({ ...response, toPostId: data.postId, toCommentId: data.commentId }))
        : dispatch(moreComments({ ...response, toPostId: data.postId, toCommentId: data.commentId }));

      if (!response.data) {
        throw new Error(intl.intl().formatMessage({ id: 'getCommentsServerError' }));
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// =============Liking==================================

export const likeComment = createAsyncThunk(
  'posts/likeComment',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const response = await likePostAPI(data);
      // dispatch(getPosts());

      if (response.status === 200) {
        if (window.location.href.includes('/profile')) {
          if (data.isSubcomment) {
            dispatch(likeSubcommentReducer({ ...data, certainPagePosts: 'userPosts' }))
          } else {
            dispatch(likeCommentReducer({ ...data, certainPagePosts: 'userPosts' }))
          }
          // dispatch(rerenderPostComment(response.data))
        } else if (window.location.href.includes('/home')) {
          if (data.isSubcomment) {
            dispatch(likeSubcommentReducer({ ...data, certainPagePosts: 'feedPosts' }))
          } else {
            dispatch(likeCommentReducer({ ...data, certainPagePosts: 'feedPosts' }))
          }
          // dispatch(rerenderFeedComment(response.data))
        } else if (window.location.href.includes('/posts')) {
          if (data.isSubcomment) {
            dispatch(likeSubcommentReducer({ ...data, certainPagePosts: 'reportsPosts' }))
          } else {
            dispatch(likeCommentReducer({ ...data, certainPagePosts: 'reportsPosts' }))
          }
        } else if (window.location.href.includes('/post')) {
          if (data.isSubcomment) {
            dispatch(likeSubcommentReducer({ ...data, certainPagePosts: 'singlePost' }))
          } else {
            dispatch(likeCommentReducer({ ...data, certainPagePosts: 'singlePost' }))
          }
        } else if (window.location.href.includes('/groups')) {
          if (data.isSubcomment) {
            dispatch(likeSubcommentReducer({ ...data, certainPagePosts: 'groupPosts' }))
          } else {
            dispatch(likeCommentReducer({ ...data, certainPagePosts: 'groupPosts' }))
          }
          // dispatch(rerenderGroupComment(response.data))
        } else if (window.location.href.includes('/activity-log')) {
          if (data.isSubcomment) {
            dispatch(likeSubcommentReducer({ ...data, certainPagePosts: 'bookmaredPosts' }))
          } else {
            dispatch(likeCommentReducer({ ...data, certainPagePosts: 'bookmaredPosts' }))
          }
          // dispatch(rerenderBookmarkedComment(response.data))
        }
      }

      if (response.status === 400) {
        if (window.location.href.includes('/profile')) {
          dispatch(deletePostComment({ commentId: data.id }));
        } else if (window.location.href.includes('/home')) {
          dispatch(deleteFeedComment({ commentId: data.id }));
        } else if (window.location.href.includes('/post')) {
          dispatch(deleteSinglePostComment({ commentId: data.id }));
        } else if (window.location.href.includes('/groups')) {
          dispatch(deleteGroupComment({ commentId: data.id }));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(deleteBookmarkedComment({ commentId: data.id }));
        }
      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// =================================================================

export const getMoreMyFeedPosts = createAsyncThunk(
  'posts/getMoreMyFeedPosts',
  async function (limit: number, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const posts = await getMorePostsApi(limit);
      dispatch(setPosts(posts));
      dispatch(showLoader(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(showLoader(false));
        throw new Error('not working');
      }
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// =================BookmarkPost=====================================

export const bookmarkPost = createAsyncThunk(
  'posts/bookmarkPost',
  async function (data: BookmarkPost, { rejectWithValue, dispatch }) {
    try {
      const response = await bookmarkPostAPI(data);
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('skip') || 0;

      if (response?.status === 201 && !data?.isBookmarked) {
        dispatch(getBookmarkedPosts(+myParam));
      }

      if (response.status === 400) {
        dispatch(removePost(data.postId));
        dispatch(removePostFeed(data.postId));
      }

      if (!response.data) {
        throw new Error(intl.intl().formatMessage({ id: 'bookmarkPostServerError' }));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getBookmarkedPosts = createAsyncThunk(
  'posts/getBookmarkedPosts',
  async function (skip: number, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));

    try {
      const response = await getBookmarkedPostsAPI(skip);

      const newData = {
        skip,
        response: response?.data[0]
      }

      dispatch(setBookmarkedPostCount(response?.data[1]));
      dispatch(setBookmarkedPost(newData));
      dispatch(showLoader(false));
      if (!response.data) {
        throw new Error(intl.intl().formatMessage({ id: 'bookmarkPostServerError' }));
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const sharePost = createAsyncThunk(
  'posts/sharePost',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const post = await postShareAPI(data.postId, data.sharePostDescr);

      // if (window.location.href.includes('/profile') && data.someUserId === data.profileId) {
      //   dispatch(addNewPostProfile(post.data));
      // }

      if (post.status === 200) {
        if (window.location.href.includes('/profile')) {
          // dispatch(rerenderPost(post.data));
          if (data.someUserId === data.profileId) {
            dispatch(addNewPostProfile(post.data));
          }
        } else if (window.location.href.includes('/home')) {
          dispatch(addNewPostHome(post.data));
          dispatch(increaseSharesCounter({
            comment: post.data, postId: data.postId
          }));
          // dispatch(rerenderFeedPost(post.data));
        } else if (window.location.href.includes('/post')) {
          dispatch(rerenderSinglePost(post.data));
        } else if (window.location.href.includes('/groups')) {
          dispatch(addNewPostGroup(post.data));
          dispatch(increaseSharesCounter({
            comment: post.data, postId: data.postId
          }));
          dispatch(rerenderGroupPost(post.data));
        } else if (window.location.href.includes('/activity-log')) {
          dispatch(rerenderBookmarkedPost(post.data));
        }
      }

      if (post.status === 400) {
        dispatch(removePost(data.postId));
        dispatch(removePostFeed(data.postId));
        dispatch(removeSinglePost());
      }

    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// =========================Last Activity============================

export const setLastActivity = createAsyncThunk(
  'posts/setLastActivity',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      dispatch(setAvtivity({ ...data, created: Date.now(), checked: false }));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const setLastActivityArray = createAsyncThunk(
  'posts/setLastActivityArray',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {

      const res = await getLastActivityAPI(data);
      const newDate = {
        skip: data.skip,
        data: res?.data?.userFrom[0],
      }
      dispatch(setActivityArr(newDate));
      dispatch(setNotificationsLength(res?.data?.userFrom[1]));
      dispatch(showLoader(false));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// =================================================================

export const fetchPostsReports = createAsyncThunk(
  'posts/fetchPostsReports',
  async function (skip: number, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const posts = await getPostsReports(skip);

      const dataNew = {
        skip,
        posts: posts[0]
      }

      dispatch(getReportsPosts(dataNew));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      if (error.response) {
        dispatch(showLoader(false));
        throw rejectWithValue(error.response);
      }
    }
  },
);

export const removePostFromReport = createAsyncThunk(
  'posts/removePostFromReport ',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {

      await deleteReportPost(data.postId);

      dispatch(removePost(data.postId));
      dispatch(removePostFeed(data.postId));
      dispatch(removeReportPost(data.postId));
      dispatch(skipReportPost(data.postId));
    } catch (error: any) {
      if (error.response) {
        throw rejectWithValue(error.response);
      }
    }
  },
);

export const skipPostFromReport = createAsyncThunk(
  'posts/skipPostFromReport',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      await ignorReportPost(data);
      dispatch(skipReportPost(data.postId));
    } catch (error: any) {
      if (error.response) {
        throw rejectWithValue(error.response);
      }
    }
  },
);

// ======================Feed Post Interests=========================

export const getPostInterests = createAsyncThunk(
  'posts/getPostInterests',
  async function (postId: number, { rejectWithValue, dispatch }) {
    try {
      const interests = await getPostInterestsAPI(postId);
      dispatch(setSelectedInterests(interests.data));
      dispatch(setInterests({ id: postId, interests: interests.data }));

      if (!interests.data) {
        throw new Error(intl.intl().formatMessage({ id: 'getCommentsServerError' }));
      }
      return interests.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPostShared = createAsyncThunk(
  'posts/getPostShared',
  async function (postId: number, { rejectWithValue, dispatch }) {
    try {
      const shares = await getPostSharedAPI(postId);

      dispatch(setShares({ id: postId, shares: shares.data }));

      if (!shares.data) {
        throw new Error(intl.intl().formatMessage({ id: 'getCommentsServerError' }));
      }
      return shares.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPostReactions = createAsyncThunk(
  'posts/getPostReactions',
  async function (obj: any, { rejectWithValue, dispatch }) {
    const { mobile, postId } = obj;
    if (mobile) {
      dispatch(showLoader(true));
    };
    try {
      const reactions = await getPostReactionsAPI(postId);
      dispatch(setFeedReactions({ id: postId, reactions: reactions.data }));
      if (mobile) {
        dispatch(showLoader(false));
      };

      if (!reactions.data) {
        throw new Error(intl.intl().formatMessage({ id: 'getCommentsServerError' }));
      }

      return reactions.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPostReportReasons = createAsyncThunk(
  'posts/getPostReportReasons',
  async function (id: number, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const reasons = await getReportsReasons(id);

      dispatch(getReportReasons(reasons));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      if (error.response) {
        dispatch(showLoader(false));
        throw rejectWithValue(error.response);
      }
    }
  },
);

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setChoosenTags(state, action) {
      state.choosenTags = action.payload;
    },
    setSelectedInterests(state, action) {
      state.selectedInterests = action.payload;
    },
    setPosts(state, action) {
      state.userPosts = action.payload;
    },
    setFeedPosts(state, action) {
      state.feedPosts = action.payload;
    },
    setGroupPosts(state, action) {
      state.groupPosts = action.payload;
    },
    setHasNewPosts(state, action) {
      state.hasNewPosts = action.payload;
    },
    setTypeOfNewPost(state, action) {
      state.typeOfNewPost = action.payload;
    },
    setErrorNull(state) {
      state.error = null;
      state.status = '';
    },
    editPostReducer(state, action) {
      const certainPagePosts = action.payload.certainPagePosts
      let selectedArray: any = [];

      if (certainPagePosts === 'userPosts') {
        selectedArray = state.userPosts
      } else if (certainPagePosts === 'feedPosts') {
        selectedArray = state.feedPosts
      } else if (certainPagePosts === 'groupPosts') {
        selectedArray = state.groupPosts
      } else if (certainPagePosts === 'bookmaredPosts') {
        selectedArray = state.bookmarkedPosts
      }

      for (const index in selectedArray) {
        const post = selectedArray[index];

        if (post.id === action.payload.postId) {
          selectedArray[index] = {
            ...post,
            title: action.payload.title,
            description: action.payload.description,
            showEmail: action.payload.showEmail,
            // title: action.payload.title,
            // title: action.payload.title,
          };
          // break;
        }

        if (action.payload.shares?.filter((item: any) => item.id === post.id).length) {
          selectedArray[index].originalPost = action.payload;
          // break;
        }


        if (post.id === action.payload?.originalPost?.id) {
          selectedArray[index].shares = action.payload?.originalPost?.shares || []
        }
      }

    },
    rerenderPost(state, action) {
      for (const index in state.userPosts) {
        const post = state.userPosts[index];

        if (post.id === action.payload?.id) {
          state.userPosts[index] = action.payload;
        }

        if (action.payload?.shares && action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.userPosts[index].originalPost = action.payload;
        }

        if (post.originalPost?.id === action.payload?.id) {
          state.userPosts[index].originalPost = action.payload;
        }

        if (action.payload?.shares && action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.userPosts[index].originalPost.answers = action.payload?.answers;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.userPosts[index].shares = action.payload?.originalPost?.shares || []
          state.userPosts[index].counter_shares = action.payload?.originalPost?.shares?.length
        }
      }
    },
    rerenderFeedPost(state, action) {
      for (const index in state.feedPosts) {
        const post = state.feedPosts[index];

        if (post.id === action.payload?.id) {
          state.feedPosts[index] = action.payload;
        }

        if (post.originalPost?.id === action.payload?.id) {
          state.feedPosts[index].originalPost = action.payload;
        }

        if (action.payload?.shares && action.payload.shares?.filter((item: any) => item.id === post.id).length) {
          state.feedPosts[index].originalPost.answers = action.payload?.answers;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.feedPosts[index].shares = action.payload?.originalPost?.shares || []
          state.feedPosts[index].counter_shares = action.payload?.originalPost?.shares?.length
        }
      }
    },
    rerenderReportsPost(state, action) {
      for (const index in state.reportsPosts) {
        const post = state.reportsPosts[index].post;

        if (post.id === action.payload?.id) {
          state.reportsPosts[index].post = {
            ...state.reportsPosts[index].post,
            answers: action.payload?.answers,
          };
          // break;
        }

        if (action.payload?.shares && action.payload?.shares
          ?.filter((item: any) => item.id === post.id).length) {
          state.reportsPosts[index].post.originalPost = action.payload;
          // break;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.reportsPosts[index].post.shares = action.payload?.originalPost?.shares || []
          state.reportsPosts[index].post.counter_shares = action.payload?.originalPost?.shares?.length
        }
      }
    },
    rerenderSinglePost(state, action) {
      for (const index in state.singlePost) {
        const post = state.singlePost[index];

        if (post.id === action.payload.id) {
          state.singlePost[index] = action.payload;
        }

        if (action.payload?.shares && action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.singlePost[index].originalPost = action.payload;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.singlePost[index].shares = action.payload?.originalPost?.shares || []
          state.singlePost[index].counter_shares = action.payload?.originalPost?.shares?.length
        }
      }
    },
    rerenderGroupPost(state, action) {
      for (const index in state.groupPosts) {
        const post = state.groupPosts[index];

        if (post.id === action.payload.id) {
          state.groupPosts[index] = action.payload;
        }

        if (post.id === action.payload.id) {
          state.groupPosts[index].answers = action.payload?.answers;
        }

        if (action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.groupPosts[index].originalPost.answers = action.payload?.answers;
        }

        if (post.originalPost?.id === action.payload.id) {
          state.groupPosts[index].originalPost = action.payload;
        }

        if (action.payload?.shares && action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.groupPosts[index].originalPost.answers = action.payload?.answers;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.groupPosts[index].shares = action.payload?.originalPost?.shares || []
          state.groupPosts[index].counter_shares = action.payload?.originalPost?.shares.length
        }
      }
    },
    rerenderBookmarkedPost(state, action) {
      for (const index in state.bookmarkedPosts) {
        const post = state.bookmarkedPosts[index];

        if (post.id === action.payload.id) {
          state.bookmarkedPosts[index] = action.payload;
        }

        if (action.payload?.shares && action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.bookmarkedPosts[index].originalPost = action.payload;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.bookmarkedPosts[index].shares = action.payload?.originalPost?.shares || []
        }

        if (post.originalPost?.id === action.payload?.id) {
          state.bookmarkedPosts[index].originalPost = action.payload;
        }

        if (action.payload?.shares && action.payload?.shares?.filter((item: any) => item.id === post.id).length) {
          state.bookmarkedPosts[index].originalPost.answers = action.payload?.answers;
        }

        if (post.id === action.payload?.originalPost?.id) {
          state.bookmarkedPosts[index].shares = action.payload?.originalPost?.shares || []
          state.bookmarkedPosts[index].counter_shares = action.payload?.originalPost?.shares?.length
        }
      }
    },
    removeBookmarkedPost(state, action) {
      const posts = state.bookmarkedPosts;
      state.bookmarkedPosts = posts.filter(post => post.id !== action.payload);
    },
    removePost(state, action) {
      state.userPosts = state.userPosts.filter(post => post.id !== action.payload);
      state.userPosts = state.userPosts.filter(post => post?.originalPost.id !== action.payload);
    },
    removeSinglePost(state) {
      state.singlePost = [];
    },
    removePostFeed(state, action) {
      state.feedPosts = state.feedPosts.filter(post => post.id !== action.payload);
      state.feedPosts = state.feedPosts.filter(post => post?.originalPost.id !== action.payload);
    },
    removePostGroups(state, action) {
      state.groupPosts = state.groupPosts.filter(post => post.id !== action.payload);
      state.groupPosts = state.groupPosts.filter(post => post?.originalPost.id !== action.payload);
    },
    removeReportPosts(state, action) {
      state.userPosts = state.userPosts.filter(post => post?.ownerId !== action.payload);
      state.userPosts = state.userPosts.filter(post => post?.owner?.id !== action.payload);
      state.feedPosts = state.feedPosts.filter(post => post?.ownerId !== action.payload);
      state.feedPosts = state.feedPosts.filter(post => post?.owner?.id !== action.payload);
      state.groupPosts = state.groupPosts.filter(post => post?.ownerId !== action.payload);
      state.groupPosts = state.groupPosts.filter(post => post?.owner?.id !== action.payload);
    },
    loadMorePosts(state, action) {
      state.feedPosts = [...state.feedPosts, ...action.payload];
    },
    setEditedPost(state, action) {
      state.editedPost = action.payload;
    },
    addNewPostProfile(state, action) {
      const originalPost = !action.payload.originalPost
        ? null
        : state.userPosts.filter(userPost => (
          userPost.id === action.payload.originalPost.id
        ))[0];

      state.userPosts = [
        {
          ...action.payload,
          isBookmarked: [],
          counter_shares: 0,
          counter_comments: 0,
          counter_interests:
            action.payload.interests
              ? action.payload.interests.length
              : action.payload.counter_interests,
          counter_reactions: 0,
          originalPost:
          {
            ...originalPost,
            counter_shares:
              originalPost ? originalPost.counter_shares + 1 : 0,
          },
        },
        ...state.userPosts,
      ];
    },
    addNewPostHome(state, action) {
      const originalPost = !action.payload.originalPost
        ? null
        : state.feedPosts.filter(feedPost => (
          feedPost.id === action.payload.originalPost.id
        ))[0];

      state.feedPosts = [
        {
          ...action.payload,
          isBookmarked: 0,
          counter_shares: 0,
          counter_comments: 0,
          counter_interests:
            action.payload.interests
              ? action.payload.interests.length
              : action.payload.counter_interests,
          counter_reactions: 0,
          originalPost:
          {
            ...originalPost,
            counter_shares:
              originalPost ? originalPost.counter_shares + 1 : 0,
          },
        },
        ...state.feedPosts,
      ];
    },
    addNewPostGroup(state, action) {
      const originalPost = !action.payload.originalPost
        ? null
        : state.groupPosts.filter(groupPosts => (
          groupPosts.id === action.payload.originalPost.id
        ))[0];

      state.groupPosts = [
        {
          ...action.payload,
          isBookmarked: [],
          counter_shares: 0,
          counter_comments: 0,
          counter_interests:
            action.payload.interests
              ? action.payload.interests.length
              : action.payload.counter_interests,
          counter_reactions: 0,
          originalPost:
          {
            ...originalPost,
            counter_shares:
              originalPost ? originalPost.counter_shares + 1 : 0,
          },
        },
        ...state.groupPosts,
      ];
    },
    setTotalPosts(state, action) {
      state.totalPosts = action.payload
    },
    concatPosts(state, action) {
      state.userPosts = state.userPosts.concat(...action.payload)
    },
    setBookmarkedPost(state, action) {
      const { skip, response } = action.payload;
      const mewBookmarked = response.map((post: any) => post.postId)

      if (skip === 0) {
        state.bookmarkedPosts = mewBookmarked;
      } else {
        state.bookmarkedPosts = [...state.bookmarkedPosts, ...mewBookmarked];
      }
    },
    setBookmarkedLength(state, action) {
      state.bookmarkedLength = action.payload;
    },
    setBookmarkedPostFeed(state, action) {
      state.bookmarkedPostsFeed = action.payload;
    },
    concatGroupsPosts(state, action) {
      state.groupPosts = [...state.groupPosts, ...action.payload]
    },
    likeCommentReducer(state, action) {
      const certainPagePosts = action.payload.certainPagePosts
      let selectedArray: any = [];

      if (certainPagePosts === 'userPosts') {
        selectedArray = state.userPosts
      } else if (certainPagePosts === 'feedPosts') {
        selectedArray = state.feedPosts
      } else if (certainPagePosts === 'singlePost') {
        selectedArray = state.singlePost
      } else if (certainPagePosts === 'groupPosts') {
        selectedArray = state.groupPosts
      } else if (certainPagePosts === 'bookmaredPosts') {
        selectedArray = state.bookmarkedPosts
      } else if (certainPagePosts === 'reportsPosts') {
        selectedArray = state.reportsPosts
      }


      for (const index in selectedArray) {
        const post = certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        let isDone = false;

        if (post?.comments && post?.comments.length) {
          for (const indexComment in post.comments) {
            const comment = post.comments[indexComment];

            if (comment.id === action.payload.id) {
              const commentIndexForLike = post.comments[indexComment].liking
                .findIndex((item: any) => item?.fromUser?.id === action.payload.ownerId)

              if (commentIndexForLike === -1) {
                const oldLiking = post.comments[indexComment].liking
                oldLiking.push({
                  id: action.payload.id,
                  type: action.payload.type,
                  fromUser: {
                    id: action.payload.ownerId,
                  }
                })
                post.comments[indexComment].liking = oldLiking
              } else {


                if (post.comments[indexComment].liking[commentIndexForLike].type === action.payload.type) {
                  post.comments[indexComment].liking.splice(commentIndexForLike, 1)
                } else {
                  post.comments[indexComment].liking[commentIndexForLike].type = action.payload.type
                }
              }

              isDone = true;
              break;
            }
          }
        }

        if (isDone) break;
      }

      for (const index in selectedArray) {
        const post = certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        if (post?.originalPost && post.originalPost?.comments && post.originalPost.comments?.length) {

          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            if (comment.id === action.payload.id) {

              const commentIndexForLike = post.originalPost.comments[indexComment].liking
                .findIndex((item: any) => item?.fromUser?.id === action.payload.ownerId)

              if (commentIndexForLike === -1) {
                const oldLiking = post.originalPost.comments[indexComment].liking
                oldLiking.push({
                  id: action.payload.id,
                  type: action.payload.type,
                  fromUser: {
                    id: action.payload.ownerId,
                  }
                })
                post.originalPost.comments[indexComment].liking = oldLiking
              } else {
                if (post.originalPost.comments[indexComment].liking[commentIndexForLike].type === action.payload.type) {
                  post.originalPost.comments[indexComment].liking.splice(commentIndexForLike, 1)
                } else {
                  post.originalPost.comments[indexComment].liking[commentIndexForLike].type = action.payload.type
                }
              }

              break;
            }
          }
        }
      }
    },
    likeSubcommentReducer(state, action) {
      const certainPagePosts = action.payload.certainPagePosts
      let selectedArray: any = [];

      if (certainPagePosts === 'userPosts') {
        selectedArray = state.userPosts
      } else if (certainPagePosts === 'feedPosts') {
        selectedArray = state.feedPosts
      } else if (certainPagePosts === 'singlePost') {
        selectedArray = state.singlePost
      } else if (certainPagePosts === 'groupPosts') {
        selectedArray = state.groupPosts
      } else if (certainPagePosts === 'bookmaredPosts') {
        selectedArray = state.bookmarkedPosts
      } else if (certainPagePosts === 'reportsPosts') {
        selectedArray = state.reportsPosts
      }

      for (const index in selectedArray) {
        const post = certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        let isDone = false;

        if (post?.comments && post?.comments.length) {
          for (const indexComment in post.comments) {
            const comment = post.comments[indexComment];

            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment]

              if (subcomment.id === action.payload.id) {
                const commentIndexForLike = post.comments[indexComment].subcomments[indexSubcomment].liking
                  .findIndex((item: any) => item?.fromUser?.id === action.payload.ownerId)

                if (commentIndexForLike === -1) {
                  const oldLiking = post.comments[indexComment].subcomments[indexSubcomment].liking
                  oldLiking.push({
                    id: action.payload.id,
                    type: action.payload.type,
                    fromUser: {
                      id: action.payload.ownerId,
                    }
                  })
                  post.comments[indexComment].subcomments[indexSubcomment].liking = oldLiking
                } else {
                  if (post.comments[indexComment].subcomments[indexSubcomment].liking[commentIndexForLike].type === action.payload.type) {
                    post.comments[indexComment].subcomments[indexSubcomment].liking.splice(commentIndexForLike, 1)
                  } else {
                    post.comments[indexComment].subcomments[indexSubcomment].liking[commentIndexForLike].type = action.payload.type
                  }
                }

                isDone = true;
                break;
              }
            }
          }
        }

        if (isDone) break;
      }

      for (const index in selectedArray) {
        const post = certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        if (post?.originalPost && post.originalPost?.comments && post.originalPost.comments?.length) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment]

              if (subcomment.id === action.payload.id) {
                const commentIndexForLike = post.originalPost.comments[indexComment].subcomments[indexSubcomment].liking
                  .findIndex((item: any) => item?.fromUser?.id === action.payload.ownerId)

                if (commentIndexForLike === -1) {
                  const oldLiking = post.originalPost.comments[indexComment].subcomments[indexSubcomment].liking
                  oldLiking.push({
                    id: action.payload.id,
                    type: action.payload.type,
                    fromUser: {
                      id: action.payload.ownerId,
                    }
                  })
                  post.originalPost.comments[indexComment].subcomments[indexSubcomment].liking = oldLiking
                } else {
                  if (post.originalPost.comments[indexComment].subcomments[indexSubcomment].liking[commentIndexForLike].type === action.payload.type) {
                    post.originalPost.comments[indexComment].subcomments[indexSubcomment].liking.splice(commentIndexForLike, 1)
                  } else {
                    post.originalPost.comments[indexComment].subcomments[indexSubcomment].liking[commentIndexForLike].type = action.payload.type
                  }
                }

                break;
              }
            }
          }
        }

      }
    },
    editCommentReducer(state, action) {
      const certainPagePosts = action.payload.certainPagePosts
      let selectedArray: any = [];

      if (certainPagePosts === 'userPosts') {
        selectedArray = state.userPosts
      } else if (certainPagePosts === 'feedPosts') {
        selectedArray = state.feedPosts
      } else if (certainPagePosts === 'groupPosts') {
        selectedArray = state.groupPosts
      } else if (certainPagePosts === 'bookmaredPosts') {
        selectedArray = state.bookmarkedPosts
      } else if (certainPagePosts === 'reportsPosts') {
        selectedArray = state.reportsPosts
      }

      for (const index in selectedArray) {
        const post = certainPagePosts === 'bookmaredPosts' ? selectedArray[+index].postId :
          certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        if (post?.comments && post.comments?.length) {
          for (const indexComment in post.comments) {
            const comment = post.comments[indexComment];
            if (comment.id === action.payload.id) {
              post.comments[indexComment].text = action.payload.text
            }
          }
        }

        if (post?.originalPost && post.originalPost?.comments && post.originalPost?.comments?.length) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];
            if (comment.id === action.payload.id) {
              post.originalPost.comments[indexComment].text = action.payload.text
            }
          }
        }
      }

      for (const index in selectedArray) {
        const post = certainPagePosts === 'bookmaredPosts' ? selectedArray[+index].postId :
          certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        if (post?.comments && post.comments?.length) {
          for (const indexComment in post.comments) {
            const comment = post.comments[indexComment];
            if (comment?.subcomments && comment.subcomments?.length) {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = post.comments[indexComment].subcomments[indexSubcomment];
                if (subcomment.id === action.payload.id) {
                  post.comments[indexComment].subcomments[indexSubcomment].text = action.payload.text
                }
              }
            }

          }
        }

        if (post?.originalPost && post.originalPost?.comments && post.originalPost.comments?.length) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];
            if (comment?.subcomments && comment.subcomments?.length) {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = post.originalPost.comments[indexComment].subcomments[indexSubcomment];
                if (subcomment.id === action.payload.id) {
                  post.originalPost.comments[indexComment].subcomments[indexSubcomment].text = action.payload.text
                }
              }
            }

          }
        }
      }
    },
    rerenderPostComment(state, action) {
      for (const index in state.userPosts) {
        const post = state.userPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          if (comment.id === action.payload.id) {
            state.userPosts[index].comments[indexComment] = action.payload;
            isDone = true;
            break;
          }
        }
        if (isDone) break;
      }

      for (const index in state.userPosts) {
        const post = state.userPosts[index];

        if (post?.originalPost?.id === action.payload.toPost.id) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            if (comment.id === action.payload.id) {
              state.userPosts[index].originalPost.comments[indexComment] = action.payload;
              break;
            }
          }

          break;
        }
      }
    },
    rerenderFeedComment(state, action) {
      for (const index in state.feedPosts) {
        const post = state.feedPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          if (comment.id === action.payload.id) {
            state.feedPosts[index].comments[indexComment] = action.payload;
            isDone = true;
            break;
          }
        }

        if (isDone) break;
      }

      for (const index in state.feedPosts) {
        const post = state.feedPosts[index];

        if (post?.originalPost?.id === action.payload.toPost.id) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            if (comment.id === action.payload.id) {
              state.feedPosts[index].originalPost.comments[indexComment] = action.payload;
              break;
            }
          }

          break;
        }
      }
    },
    rerenderGroupComment(state, action) {
      for (const index in state.groupPosts) {
        const post = state.groupPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          if (comment.id === action.payload.id) {
            state.groupPosts[index].comments[indexComment] = action.payload;
            isDone = true;
            break;
          }
        }

        if (isDone) break;
      }

      for (const index in state.groupPosts) {
        const post = state.groupPosts[index];

        if (post?.originalPost?.id === action.payload.toPost.id) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            if (comment.id === action.payload.id) {
              state.groupPosts[index].originalPost.comments[indexComment] = action.payload;
              break;
            }
          }

          break;
        }
      }
    },
    rerenderBookmarkedComment(state, action) {
      for (const index in state.bookmarkedPosts) {
        const post = state.bookmarkedPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          if (comment.id === action.payload.id) {
            state.bookmarkedPosts[index].comments[indexComment] = action.payload;
            isDone = true;
            break;
          }
        }

        if (isDone) break;
      }

      for (const index in state.bookmarkedPosts) {
        const post = state.bookmarkedPosts[index];

        if (post?.originalPost?.id === action.payload.toPost.id) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            if (comment.id === action.payload.id) {
              state.bookmarkedPosts[index].originalPost.comments[indexComment] = action.payload;
              break;
            }
          }

          break;
        }
      }
    },
    deletePostComment(state, action) {
      for (const index in state.userPosts) {
        const post = state.userPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          let isFindTrue = false;

          if (comment.id === action.payload.commentId) {
            state.userPosts[index].comments.splice(+indexComment, 1);
            isDone = true;
            break;
          } else {
            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment];

              if (subcomment.id === action.payload.commentId) {
                state.userPosts[index].comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                isFindTrue = true;
                break;
              }
            }
          }

          if (isFindTrue) break;
        }

        if (post.originalPost !== null) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            let isFindTrue = false;

            if (comment.id === action.payload.commentId) {
              state.userPosts[index].originalPost.comments.splice(+indexComment, 1);
              isDone = true;
              break;
            } else {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = comment.subcomments[indexSubcomment];

                if (subcomment.id === action.payload.commentId) {
                  state.userPosts[index].originalPost.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                  isFindTrue = true;
                  break;
                }
              }
            }

            if (isFindTrue) break;
          }
        }

        if (isDone) break;
      }
    },
    deleteReportsComment(state, action) {
      for (const index in state.reportsPosts) {
        const post = state.reportsPosts[index].post;

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          let isFindTrue = false;

          if (comment.id === action.payload.commentId) {
            state.reportsPosts[index].post.comments.splice(+indexComment, 1);
            state.reportsPosts[index].post.counter_comments = state.reportsPosts[index].post.counter_comments - 1;
            isDone = true;
            break;
          } else {
            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment];

              if (subcomment.id === action.payload.commentId) {
                state.reportsPosts[index].post.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                isFindTrue = true;
                break;
              }
            }
          }

          if (isFindTrue) break;
        }

        if (post.originalPost !== null) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            let isFindTrue = false;

            if (comment.id === action.payload.commentId) {
              state.reportsPosts[index].post.originalPost.comments.splice(+indexComment, 1);
              isDone = true;
              break;
            } else {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = comment.subcomments[indexSubcomment];

                if (subcomment.id === action.payload.commentId) {
                  state.reportsPosts[index].post.originalPost.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                  isFindTrue = true;
                  break;
                }
              }
            }

            if (isFindTrue) break;
          }
        }

        if (isDone) break;
      }
    },
    deleteFeedComment(state, action) {
      for (const index in state.feedPosts) {
        const post = state.feedPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          let isFindTrue = false;

          if (comment.id === action.payload.commentId) {
            state.feedPosts[index].comments.splice(+indexComment, 1);
            state.feedPosts[index].counter_comments = state.feedPosts[index].counter_comments - 1;
            isDone = true;
            break;
          } else {
            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment];

              if (subcomment.id === action.payload.commentId) {
                state.feedPosts[index].comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                isFindTrue = true;
                break;
              }
            }
          }

          if (isFindTrue) break;
        }

        if (post.originalPost !== null && !!post.originalPost?.comments?.length) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            let isFindTrue = false;

            if (comment.id === action.payload.commentId) {
              state.feedPosts[index].originalPost.comments.splice(+indexComment, 1);
              isDone = true;
              break;
            } else {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = comment.subcomments[indexSubcomment];

                if (subcomment.id === action.payload.commentId) {
                  state.feedPosts[index].originalPost.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                  isFindTrue = true;
                  break;
                }
              }
            }

            if (isFindTrue) break;
          }
        }

        if (isDone) break;
      }
    },
    deleteSinglePostComment(state, action) {
      for (const index in state.singlePost) {
        const post = state.singlePost[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          let isFindTrue = false;

          if (comment.id === action.payload.commentId) {
            state.singlePost[index].comments.splice(+indexComment, 1);
            state.singlePost[index].counter_comments = state.singlePost[index].counter_comments - 1;
            isDone = true;
            break;
          } else {
            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment];

              if (subcomment.id === action.payload.commentId) {
                state.singlePost[index].comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                isFindTrue = true;
                break;
              }
            }
          }

          if (isFindTrue) break;
        }

        if (post.originalPost !== null) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            let isFindTrue = false;

            if (comment.id === action.payload.commentId) {
              state.singlePost[index].originalPost.comments.splice(+indexComment, 1);
              isDone = true;
              break;
            } else {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = comment.subcomments[indexSubcomment];

                if (subcomment.id === action.payload.commentId) {
                  state.singlePost[index].originalPost.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                  isFindTrue = true;
                  break;
                }
              }
            }

            if (isFindTrue) break;
          }
        }

        if (isDone) break;
      }
    },
    deleteGroupComment(state, action) {
      for (const index in state.groupPosts) {
        const post = state.groupPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          let isFindTrue = false;

          if (comment.id === action.payload.commentId) {
            state.groupPosts[index].comments.splice(+indexComment, 1);
            state.groupPosts[index].counter_comments = state.groupPosts[index].counter_comments - 1;
            isDone = true;
            break;
          } else {
            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment];

              if (subcomment.id === action.payload.commentId) {
                state.groupPosts[index].comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                isFindTrue = true;
                break;
              }
            }
          }

          if (isFindTrue) break;
        }

        if (post.originalPost !== null) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            let isFindTrue = false;

            if (comment.id === action.payload.commentId) {
              state.groupPosts[index].originalPost.comments.splice(+indexComment, 1);
              isDone = true;
              break;
            } else {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = comment.subcomments[indexSubcomment];

                if (subcomment.id === action.payload.commentId) {
                  state.groupPosts[index].originalPost.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                  isFindTrue = true;
                  break;
                }
              }
            }

            if (isFindTrue) break;
          }
        }

        if (isDone) break;
      }
    },
    deleteBookmarkedComment(state, action) {
      for (const index in state.bookmarkedPosts) {
        const post = state.bookmarkedPosts[index];

        let isDone = false;

        for (const indexComment in post.comments) {
          const comment = post.comments[indexComment];

          let isFindTrue = false;

          if (comment.id === action.payload.commentId) {
            state.bookmarkedPosts[index].comments.splice(+indexComment, 1);
            state.bookmarkedPosts[index].counter_comments = state.bookmarkedPosts[index].counter_comments - 1;
            isDone = true;
            break;
          } else {
            for (const indexSubcomment in comment.subcomments) {
              const subcomment = comment.subcomments[indexSubcomment];

              if (subcomment.id === action.payload.commentId) {
                state.bookmarkedPosts[index].comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                isFindTrue = true;
                break;
              }
            }
          }

          if (isFindTrue) break;
        }

        if (post.originalPost !== null) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            let isFindTrue = false;

            if (comment.id === action.payload.commentId) {
              state.bookmarkedPosts[index].originalPost.comments.splice(+indexComment, 1);
              isDone = true;
              break;
            } else {
              for (const indexSubcomment in comment.subcomments) {
                const subcomment = comment.subcomments[indexSubcomment];

                if (subcomment.id === action.payload.commentId) {
                  state.bookmarkedPosts[index].originalPost.comments[indexComment].subcomments.splice(+indexSubcomment, 1);
                  isFindTrue = true;
                  break;
                }
              }
            }

            if (isFindTrue) break;
          }
        }

        if (isDone) break;
      }
    },
    addNewComments(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post = window.location.href.includes('/posts') ?
          posts[index].post : posts[index];

        if (post.id === action.payload.postId.id) {
          let oldComments = post.comments

          if (oldComments === null || oldComments === undefined) {
            oldComments = []
          }

          post.comments = [
            ...oldComments,
            action.payload.comment
          ];

          post.totalComments = post.totalComments + 1
          break;
        }
      }


      for (const index in posts) {
        const post = posts[index];

        if (post.originalPost?.id === action.payload.postId.id) {
          post.originalPost.counter_comments = post?.originalPost?.counter_comments + 1;

          let newComments = post.originalPost?.comments

          if (newComments === undefined) {
            newComments = []
          }

          post.originalPost.comments = [...newComments, action.payload.comment];
          post.originalPost.totalComments = post.originalPost.totalComments + 1
        }
      }
    },
    addNewSubcomments(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post = window.location.href.includes('/posts') ?
          posts[index].post : posts[index];

        if (post?.comments && post.comments.length) {
          for (const indexComment in post.comments) {
            const comment = post.comments[indexComment];

            if (comment.id === action.payload.commentId.id) {
              post.comments[indexComment].subcomments =
                [...action.payload.subcomment.subcomments];
            }
          }
        }

        if (Object.keys(post).includes('originalPost') && post?.originalPost !== null && post.originalPost?.comments) {
          for (const indexComment in post.originalPost.comments) {
            const comment = post.originalPost.comments[indexComment];

            if (comment.id === action.payload.commentId.id) {
              post.originalPost.comments[indexComment].subcomments =
                [...action.payload.subcomment.subcomments];
            }
          }
        }
      }
    },
    increaseCommentCounter(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post = window.location.href.includes('/posts') ? posts[index].post : posts[index];

        if (post.id === action.payload.postId.id) {
          post.counter_comments = post.counter_comments + 1;
        }
      }
    },
    increaseSharesCounter(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post = posts[index];

        if (post.id === action.payload.postId) {
          posts[index].counter_shares = posts[index].counter_shares + 1;
          break;
        }
      }
    },
    decreaseSharesCounter(state, action) {
      const posts: any = getPagePosts(state);

      const delPost = posts.filter((post: any) => (
        post.id === action.payload
      ));

      for (const index in posts) {
        const post = posts[index];

        if (post.id === delPost[0].originalPost.id) {
          posts[index].counter_shares = posts[index].counter_shares - 1;
          break;
        }
      }
    },
    setComments(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post =
          window.location.href.includes('/posts') ? posts[index].post : posts[index];

        if (typeof action.payload.toPostId === 'number') {
          if (action.payload[0] !== 0 &&
            post.id === action.payload.toPostId) {

            for (const subIndex in post.comments) {
              const comment = post.comments[subIndex]

              if (comment.id === action.payload.toCommentId) {
                comment.subcomments = action.payload[0]
              }
            }
          }

          if (action.payload[0] !== 0 &&
            post.originalPost?.id === action.payload.toPostId) {

            for (const subIndex in post.originalPost.comments) {
              const comment = post.originalPost.comments[subIndex]

              if (comment.id === action.payload.toCommentId) {
                comment.subcomments = action.payload[0]
              }
            }
          }

        } else {
          if (action.payload[0] !== 0 &&
            post.id === action.payload[0][0].toPost?.id) {
            post.comments = [...action.payload[0]]
            post.totalComments = action.payload[1]
          }

          if (action.payload[0] !== 0 && post.originalPost?.id === action.payload[0][0].toPost.id) {
            post.originalPost.comments = [...action.payload[0]]
            post.originalPost.totalComments = action.payload[1]
          }
        }
      }
    },
    moreComments(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post =
          window.location.href.includes('/posts') ? posts[index].post : posts[index];

        if (typeof action.payload?.toPostId === 'number') {
          if (post.id === action.payload.toPostId) {
            const filterNewSubcomments: any = []

            for (const subIndex in post.comments) {
              const comment = post.comments[subIndex]

              if (comment.id === action.payload.toCommentId) {
                action.payload[0].forEach((item: any) => {
                  let isOK = true
                  comment.subcomments.forEach((subcomment: any) => {
                    if (subcomment.id === item.id) {
                      isOK = false
                    }
                  })
                  if (isOK) { filterNewSubcomments.push(item) }
                })

                const sortedCommentsArray = [...comment.subcomments, ...filterNewSubcomments].sort((a: any, b: any) => {
                  const firstArgument = new Date(a.created)
                  const secondArgument = new Date(b.created)

                  if (firstArgument > secondArgument) return 1
                  if (firstArgument < secondArgument) return -1
                  return 0
                })

                comment.subcomments = [...sortedCommentsArray]
              }
            }
          }

          if (post.originalPost?.id === action.payload.toPostId) {
            const filterNewSubcomments: any = []

            for (const subIndex in post.comments) {
              const comment = post.comments[subIndex]

              action.payload[0].forEach((item: any) => {
                let isOK = true
                comment.subcomments.forEach((subcomment: any) => {
                  if (subcomment.id === item.id) {
                    isOK = false
                  }
                })
                if (isOK) { filterNewSubcomments.push(item) }
              })

              const sortedCommentsArray = [...post.originalPost.comments, ...filterNewSubcomments].sort((a: any, b: any) => {
                const firstArgument = new Date(a.created)
                const secondArgument = new Date(b.created)

                if (firstArgument > secondArgument) return 1
                if (firstArgument < secondArgument) return -1
                return 0
              })

              comment.subcomments = [...sortedCommentsArray]
            }
          }
        } else {
          if (post.id === action.payload[0][0].toPost.id) {
            const filterNewComments: any = []

            action.payload[0].forEach((item: any) => {
              let isOK = true
              post.comments.forEach((comment: any) => {
                if (comment.id === item.id) {
                  isOK = false
                }
              })
              if (isOK) { filterNewComments.push(item) }
            })

            const sortedCommentsArray = [...post.comments, ...filterNewComments].sort((a: any, b: any) => {
              const firstArgument = new Date(a.created)
              const secondArgument = new Date(b.created)

              if (firstArgument > secondArgument) return 1
              if (firstArgument < secondArgument) return -1
              return 0
            })

            post.comments = [...sortedCommentsArray]
          }

          if (post.originalPost?.id === action.payload[0][0].toPost.id) {
            const filterNewComments: any = []

            action.payload[0].forEach((item: any) => {
              let isOK = true
              post.originalPost.comments.forEach((comment: any) => {
                if (comment.id === item.id) {
                  isOK = false
                }
              })
              if (isOK) { filterNewComments.push(item) }
            })

            const sortedCommentsArray = [...post.originalPost.comments, ...filterNewComments].sort((a: any, b: any) => {
              const firstArgument = new Date(a.created)
              const secondArgument = new Date(b.created)

              if (firstArgument > secondArgument) return 1
              if (firstArgument < secondArgument) return -1
              return 0
            })

            post.originalPost.comments = [...sortedCommentsArray]
          }
        }
      }
    },
    setAvtivity(state, action) {
      if (state.activity.length === 0) {
        state.activity = [action.payload];
      } else {
        state.activity = [action.payload, ...state.activity];
      }
      state.activity.sort((pre, next) => next.created - pre.created);
    },
    setActivityArr(state, action) {
      const { data, skip } = action.payload;

      if (+skip === 0) {
        state.activity = data;
      } else {
        state.activity = [...state.activity, ...data];
      }

      state.activity.sort((pre, next) => next.created - pre.created);
    },
    setNotificationsLength(state, action) {
      state.notifLength = action.payload;
    },
    getReportsPosts(state, action) {
      const { posts, skip } = action.payload;

      if (skip === 0) {
        state.reportsPosts = posts;
      } else {
        state.reportsPosts = [...state.reportsPosts, ...posts];
      }
    },
    getReportReasons(state, action) {
      state.postReportReasons = [...action.payload];
    },
    skipReportPost(state, action) {
      const newArr = [...state.reportsPosts].filter(post => post.post.id !== action.payload);
      state.reportsPosts = newArr;
    },
    removeReportPost(state, action) {
      const newArr = [...state.reportsPosts].filter(post => post.id !== action.payload);
      state.reportsPosts = newArr;
    },
    removeAllReportPosts(state, action) {
      const newArr = [...state.reportsPosts].filter(post => post.post.id !== action.payload && post.post.originalPost?.id !== action.payload);
      state.reportsPosts = newArr;
    },
    setInterests(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post = posts[index];

        if (action.payload.interests.length !== 0) {
          if (post?.id === action.payload.id) {
            posts[index] = {
              ...posts[index],
              interests: [...action.payload.interests],
            };
          } else if (post?.post?.id === action.payload.id) {
            posts[index].post = {
              ...posts[index].post,
              interests: [...action.payload.interests],
            };
          } else if (post?.originalPost?.id === action.payload.id) {
            posts[index].originalPost = {
              ...posts[index].originalPost,
              interests: [...action.payload.interests],
            };
          } else if (post?.post?.originalPost?.id === action.payload.id) {
            posts[index].originalPost = {
              ...posts[index].post.originalPost,
              interests: [...action.payload.interests],
            };
          }
        }
      }
    },
    setShares(state, action) {
      const posts: any = getPagePosts(state);

      for (const index in posts) {
        const post =
          window.location.href.includes('/posts') ? posts[index].post : posts[index];

        if (action.payload.shares?.length !== 0 &&
          post.id === action.payload.id) {
          posts[index] = {
            ...posts[index],
            shares: [...action.payload.shares],
          }
        }

        if (action.payload.shares?.length !== 0 && post.originalPost
          && post.originalPost?.id === action.payload.id) {
          posts[index].originalPost = {
            ...posts[index].originalPost,
            shares: [...action.payload.shares],
          };
        }
      }
    },
    setBookmarkedPostCount(state, action) {
      state.bookmarkedPostCount = action.payload;
    },
    setFeedReactions(state, action) {
      const posts: any = getPagePosts(state);

      // for (const index in posts) {
      //   const post = posts[index];

      //   if (action.payload.reactions.length !== 0 &&
      //     post.id === action.payload.id) {

      //     posts[index] = {
      //       ...posts[index],
      //       reactions: [...action.payload.reactions],
      //     }
      //   }

      //   if (action.payload.reactions.length !== 0
      //     && post.originalPost?.id === action.payload.id) {
      //     posts[index].originalPost = {
      //       ...posts[index].originalPost,
      //       reactions: [...action.payload.reactions],
      //     };
      //   }
      // }

      for (const index in posts) {
        const post =
          window.location.href.includes('/posts') ? posts[index].post : posts[index];

        if (action.payload.reactions.length !== 0 &&
          post.id === action.payload.id) {

          // const newReactions = action.payload.reactions
          //   .filter((reaction: any) => reaction.fromUser.id !== post?.reactions[0]?.fromUser?.id);

          //   post.reactions = [
          //     post.reactions[0],
          //   ...newReactions
          // ];

          post.reactions = [...action.payload.reactions]
        }

        if (action.payload.reactions.length !== 0
          && post.originalPost?.id === action.payload.id) {

          // const newReactions = action.payload.reactions
          //   .filter((reaction: any) => reaction.fromUser.id !== post.originalPost?.reactions[0]?.fromUser?.id);

          //   post.originalPost.reactions = [
          //   ...post.originalPost.reactions,
          //   ...newReactions
          // ];

          post.originalPost.reactions = [...action.payload.reactions]
        }
      }
    },
    rerenderReactions(state, action) {
      const certainPagePosts = action.payload.certainPagePosts
      let selectedArray: any = [];

      if (certainPagePosts === 'userPosts') {
        selectedArray = state.userPosts
      } else if (certainPagePosts === 'feedPosts') {
        selectedArray = state.feedPosts
      } else if (certainPagePosts === 'groupPosts') {
        selectedArray = state.groupPosts
      } else if (certainPagePosts === 'bookmaredPosts') {
        selectedArray = state.bookmarkedPosts
      } else if (certainPagePosts === 'singlePost') {
        selectedArray = state.singlePost
      } else if (certainPagePosts === 'reportsPosts') {
        selectedArray = state.reportsPosts
      }


      for (const index in selectedArray) {
        const post =
          certainPagePosts === 'reportsPosts' ? selectedArray[+index].post : selectedArray[+index];

        const newReaction = {
          id: 0,
          type: action.payload.elType,
          fromUser: action.payload.fromUser
        }

        if (post.id === action.payload.postId) {
          let oldReactionsArray = post?.reactions;
          if (oldReactionsArray === null || oldReactionsArray === undefined) {
            oldReactionsArray = []
          }

          let isReactionActivated = false

          oldReactionsArray.forEach((item: any) => {
            if (item.fromUser.id === newReaction.fromUser.id) {
              if (item.type === action.payload.elType) {
                isReactionActivated = true
              }
            }
          })

          const myOldReactionIndex = oldReactionsArray.findIndex((item: any) => item.fromUser.id === newReaction.fromUser.id)

          if (oldReactionsArray.length === 0) {
            oldReactionsArray.unshift(newReaction)
            post.counter_reactions += 1
          } else {
            if (myOldReactionIndex !== -1) {
              if (isReactionActivated) {
                oldReactionsArray.splice(myOldReactionIndex, 1)
                post.counter_reactions -= 1
              } else {
                oldReactionsArray.splice(myOldReactionIndex, 1, newReaction)
              }
            } else {
              oldReactionsArray.unshift(newReaction)
              post.counter_reactions += 1
            }
          }

          post.reactions = oldReactionsArray
        }

        if ((post?.originalPost !== null && post?.originalPost !== undefined) && post?.originalPost.id === action.payload.postId) {
          let oldReactionsArray = post.originalPost?.reactions
          if (oldReactionsArray === null || oldReactionsArray === undefined) {
            oldReactionsArray = []
          }

          let isReactionActivated = false

          oldReactionsArray.forEach((item: any) => {
            if (item.fromUser.id === newReaction.fromUser.id) {
              if (item.type === action.payload.elType) {
                isReactionActivated = true
              }
            }
          })

          const myOldReactionIndex = oldReactionsArray.findIndex((item: any) => item.fromUser.id === newReaction.fromUser.id)

          if (oldReactionsArray.length === 0) {
            oldReactionsArray.unshift(newReaction)
            post.originalPost.counter_reactions += 1
          } else {
            if (myOldReactionIndex !== -1) {
              if (isReactionActivated) {
                oldReactionsArray.splice(myOldReactionIndex, 1)
                post.originalPost.counter_reactions -= 1
              } else {
                oldReactionsArray.splice(myOldReactionIndex, 1, newReaction)
              }
            } else {
              oldReactionsArray.unshift(newReaction)
              post.originalPost.counter_reactions += 1
            }
          }

          post.originalPost.reactions = oldReactionsArray
        }
      }
    },
    setSinglePost(state, action) {
      state.singlePost = action.payload;
    },
    translatePost(state, action) {
      const { id, description, title, originalPost, answers, certainPostPage } = action.payload;
      let selectedArray: any = [];

      if (certainPostPage === 'userPosts') {
        selectedArray = state.userPosts;
      } else if (certainPostPage === 'feedPosts') {
        selectedArray = state.feedPosts;
      } else if (certainPostPage === 'groupPosts') {
        selectedArray = state.groupPosts;
      } else if (certainPostPage === 'bookmaredPosts') {
        selectedArray = state.bookmarkedPosts;
      } else if (certainPostPage === 'singlePost') {
        selectedArray = state.singlePost;
      } else if (certainPostPage === 'reportsPosts') {
        selectedArray = state.reportsPosts;
      }

      const newArray = selectedArray.map((a: any) => {
        if (a.id === id) {
          return {
            ...a,
            description,
            title,
            answers,
            originalPost: {
              ...a.originalPost,
              title: originalPost?.title,
              description: originalPost?.description,
            } || {}
          }
        } else {
          return a;
        }
      });

      if (certainPostPage === 'userPosts') {
        state.userPosts = newArray;
      } else if (certainPostPage === 'feedPosts') {
        state.feedPosts = newArray;
      } else if (certainPostPage === 'groupPosts') {
        state.groupPosts = newArray;
      } else if (certainPostPage === 'bookmaredPosts') {
        state.bookmarkedPosts = newArray;
      } else if (certainPostPage === 'singlePost') {
        state.singlePost = newArray;
      } else if (certainPostPage === 'reportsPosts') {
        state.reportsPosts = newArray;
      };
    },
    setCounterFeedSkip(state, action){
      state.countFeedSkip = action.payload
    },
    setScrollFeedPos(state, action){
      state.scrollFeedPos = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createPost.pending, setLoading)
      .addCase(editPost.pending, setLoading)

      .addCase(createPost.fulfilled, setSuccess)
      .addCase(editPost.fulfilled, setSuccess)

      .addCase(createPost.rejected, setError)
      .addCase(editPost.rejected, setError)

      .addCase(getFeedPosts.pending, setLoading);
  },
});

export const {
  setScrollFeedPos,
  setCounterFeedSkip,
  rerenderReportsPost,
  deleteReportsComment,
  increaseCommentCounter,
  increaseSharesCounter,
  decreaseSharesCounter,
  deletePostComment,
  deleteFeedComment,
  deleteSinglePostComment,
  deleteGroupComment,
  deleteBookmarkedComment,
  setChoosenTags,
  setSelectedInterests,
  setPosts,
  setFeedPosts,
  setGroupPosts,
  setTypeOfNewPost,
  rerenderPostComment,
  rerenderFeedComment,
  rerenderGroupComment,
  rerenderBookmarkedComment,
  setErrorNull,
  removePost,
  removePostFeed,
  removePostGroups,
  rerenderPost,
  rerenderFeedPost,
  rerenderGroupPost,
  rerenderBookmarkedPost,
  setEditedPost,
  loadMorePosts,
  addNewPostProfile,
  addNewPostHome,
  addNewPostGroup,
  setTotalPosts,
  setHasNewPosts,
  concatPosts,
  concatGroupsPosts,
  setBookmarkedPost,
  setBookmarkedPostFeed,
  setBookmarkedLength,
  setComments,
  moreComments,
  setAvtivity,
  setActivityArr,
  setNotificationsLength,
  getReportsPosts,
  skipReportPost,
  removeReportPost,
  removeAllReportPosts,
  removeBookmarkedPost,
  setInterests,
  setFeedReactions,
  setShares,
  rerenderReactions,
  getReportReasons,
  likeCommentReducer,
  likeSubcommentReducer,
  removeSinglePost,
  editCommentReducer,
  rerenderSinglePost,
  setSinglePost,
  addNewComments,
  addNewSubcomments,
  setBookmarkedPostCount,
  translatePost,
  removeReportPosts
  // translateComment,
  // translateSubcomment,
} = postsSlice.actions;
export default postsSlice.reducer;
